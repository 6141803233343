import {CardContent, Grid, Stack, Typography} from "@mui/material";
import {CardFooter, CardOverImageTitle, CardWithShadow, ECardHeight} from "@nitra/nkmb-card-components";
import {NkmbIconFax, NkmbIconLokacija, NkmbIconMail, NkmbIconTel} from "@nitra/nkmb-theme";
import React from "react";
import {useTranslation} from "react-i18next";
import {IconAndTextLineDesktop} from "./IconAndTextLineDesktop";
import {OpeningTimeDesktopBox} from "./OpeningTimeDesktopBox";

export function MoreContactDesktop() {
	const leftCardSx = {width: "100%", my: 2};
	const {t} = useTranslation();

	return (
		<Stack direction={"row"} sx={{}}>
			<Grid container sx={{maxWidth: "912px", py: 2}}>

				<CardWithShadow disableOnHoverActions={true} cardHeight={ECardHeight.UNSET} sx={leftCardSx}>
					<CardContent>
						<Typography variant={"podnaslovL"}>{t('more.contact.clubName')}</Typography>
						<Stack direction={"row"}>
							<Stack sx={{flexGrow: 0.9}}>
								<IconAndTextLineDesktop icon={NkmbIconTel} text={"info@nkmaribor.com"}/>
								<IconAndTextLineDesktop icon={NkmbIconLokacija}
								                        text={t('more.contact.clubAddress')}/>
							</Stack>
							<Stack sx={{flexGrow: 1}}>
								<IconAndTextLineDesktop icon={NkmbIconMail} text={"02 / 228 47 00"}/>
								<IconAndTextLineDesktop icon={NkmbIconMail} text={"02 / 228 47 11"}/>
								<IconAndTextLineDesktop icon={NkmbIconFax} text={"02 / 228 47 01"}/>
							</Stack>
						</Stack>
					</CardContent>
				</CardWithShadow>

				<CardWithShadow disableOnHoverActions={true} cardHeight={ECardHeight.UNSET} sx={leftCardSx}>
					<CardContent>
						<Typography variant={"podnaslovL"} sx={{pb: 2}}>{t('more.contact.violetCottage')}</Typography>
						<Stack>
							<IconAndTextLineDesktop icon={NkmbIconTel} text={"trgovina@nkmaribor.com"}/>
						</Stack>
						<Stack direction={"row"} sx={{pt: 2}}>
							<Stack sx={{flexGrow: 0.9}}>
								<Typography sx={{pb: 2}}
								            variant={"podnaslovS"}>{t('more.contact.locations.mercatorTabor')}</Typography>
								<IconAndTextLineDesktop icon={NkmbIconLokacija}
								                        text={t('more.contact.locations.mercatorTaborAddress')}/>
								<IconAndTextLineDesktop icon={NkmbIconMail} text={"02 / 620 96 66"}/>
								<OpeningTimeDesktopBox days={[
									t('more.contact.days.monToSat'),
									t('more.contact.days.sunAndHolidays')
								]} times={[
									t('more.contact.times.8to20'),
									t('more.contact.closed')
								]}/>
							</Stack>
							<Stack sx={{flexGrow: 1}}>
								<Typography sx={{pb: 2}}
								            variant={"podnaslovS"}>{t('more.contact.cityCenter')}</Typography>
								<IconAndTextLineDesktop icon={NkmbIconLokacija}
								                        text={t('more.contact.locations.cityAddress')}/>
								<IconAndTextLineDesktop icon={NkmbIconMail} text={"02 / 620 86 96"}/>
								<OpeningTimeDesktopBox days={[
									t('more.contact.days.monToFri'),
									t('more.contact.days.sat'),
									t('more.contact.days.sunAndHolidays')
								]} times={[
									t('more.contact.times.9to19'),
									t('more.contact.times.9to14'),
									t('more.contact.closed')
								]}/>
							</Stack>
						</Stack>
					</CardContent>
				</CardWithShadow>
				<CardWithShadow disableOnHoverActions={true} cardHeight={ECardHeight.UNSET} sx={leftCardSx}>
					<CardContent>
						<Typography variant={"podnaslovL"}>{t('more.contact.hairdresser')}</Typography>
						<Stack>
							<IconAndTextLineDesktop icon={NkmbIconTel} text={"salon@nkmaribor.com"}/>
							<IconAndTextLineDesktop icon={NkmbIconLokacija}
							                        text={t('more.contact.locations.mercatorTabor') + ", " + t('more.contact.locations.mercatorTaborAddress')}/>
							<IconAndTextLineDesktop icon={NkmbIconMail} text={"02 / 620 96 67"}/>
						</Stack>
						<Stack sx={{flexGrow: 1}}>
							<OpeningTimeDesktopBox days={[
								t('more.contact.days.monToSat'),
								t('more.contact.days.sunAndHolidays')
							]} times={[
								t('more.contact.times.8to20'),
								t('more.contact.closed')
							]}/>
						</Stack>
					</CardContent>
				</CardWithShadow>
			</Grid>
			<Stack sx={{pl: 3, pt: 4}} spacing={3}>
				<CardWithShadow
					sx={{display: "flex", flexDirection: "column", position: "relative"}}
					to={"https://www.vijolcnabajta.si/si.html"}
					fixedWidth={288}
					fullSizeImageUrl={"/images/more/contact/bajta.png"}
				>
					<CardContent sx={{zIndex: 1, mt: "auto"}}>
						<CardOverImageTitle title={t('more.contact.violetCottageTitle')} variant={"podnaslovM"}/>
					</CardContent>
					<CardFooter brightText={true} shareUrl={"https://www.vijolcnabajta.si/si.html"}/>

				</CardWithShadow>
				<CardWithShadow
					sx={{display: "flex", flexDirection: "column", position: "relative"}}

					fixedWidth={288}
					to={"https://www.facebook.com/FrizerskaBajta/?locale=sl_SI"}
					fullSizeImageUrl={"/images/more/contact/frizer.png"}
				>
					<CardContent sx={{zIndex: 1, mt: "auto"}}>
						<CardOverImageTitle title={t('more.contact.hairdresserTitle')} variant={"podnaslovM"}/>
					</CardContent>
					<CardFooter brightText={true} shareUrl={"https://www.facebook.com/FrizerskaBajta/?locale=sl_SI"}/>
				</CardWithShadow>
			</Stack>
		</Stack>
	);
}