import {Typography, useTheme} from "@mui/material";
import {CardWithShadow, getLocalizedString, LocalizedStringDto} from "@nitra/nkmb-card-components";
import {useTranslation} from "react-i18next";

export function DesktopSuggestedArticleCard(props: {
	data: {
		title: LocalizedStringDto,
		firestoreId: string,
		image600x360url: string,
	}
}) {
	const {title, image600x360url, firestoreId} = props.data;
	const theme = useTheme();
	const {i18n} = useTranslation();
	const currentLang = i18n.language;


	return (
		<CardWithShadow
			to={`../${firestoreId}`}
			fullSizeImageUrl={`url(${image600x360url})`}
			fixedWidth={492}
			cardHeightOverride={278}
			sx={{position: "relative", display: "flex"}}
		>
			<Typography sx={{
				position: "absolute",
				left: 24,
				bottom: 16,
				color: theme.palette.background.default
			}} variant={"h4"}>{getLocalizedString(title, currentLang)}</Typography>
		</CardWithShadow>
	)
}