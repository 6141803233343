import {Box, Stack, Typography, useTheme} from "@mui/material";
import {CardWithShadow, useIsMobileContext} from "@nitra/nkmb-card-components";
import useIosContext from "@nitra/nkmb-card-components/contexts/IosContextProvider";
import {NationFlagRound} from "@nitra/nkmb-theme";
import React from "react";
import {Person} from "../../../models";

export function VotingPersonElement(props: {
	person: Person;
	onSelect: (person: Person) => void;
}) {
	const isMobile = useIsMobileContext();
	const {person, onSelect} = props;
	const theme = useTheme();
	const {isIos} = useIosContext();

	return (
		<CardWithShadow
			onClickSpecialAction={() => onSelect(person)}
			sx={{
				position: "relative",
				width: "100%",
				height: "100%",
				maxWidth: 331,
				maxHeight: 331,
				cursor: 'pointer',
				transition: 'outline 0.2s ease-in-out',
			}}
			fullSizeImageUrl={`url(${person.url331x331})`}
			fullSizeImageHideOverlay={true}
		>
			<Stack
				direction={"row"}
				justifyContent={"space-between"}
				sx={{
					flexGrow: 1,
					position: "absolute",
					top: 0,
					left: 0,
					right: 0,
				}}>
				{person.jerseyNumber !== undefined &&
                    <Typography variant={isMobile ? "h4" : "h2"} sx={{
						pt: 2,
						px: 2,
						color: theme.palette.primary.main,
						[theme.breakpoints.down("sm")]: isIos && {
							'@media (-webkit-min-device-pixel-ratio: 2)': {
								fontWeight: 500,
							},
						},
					}}>
						{person.jerseyNumber}
                    </Typography>
				}
				{
					person.nationality &&
                    <NationFlagRound
                        countryCode={person.nationality}
                        sx={{}}
                        svgProps={{
							height: isMobile ? 55 : 100,
							width: isMobile ? 55 : 100,
						}}
                    />
				}
			</Stack>
			<Stack direction={"column"}
			       alignItems={"center"}
			       sx={{
				       position: "absolute",
				       bottom: 0,
				       left: 0,
				       right: 0,
				       height: "71px",
				       backgroundColor: "rgba(255, 255, 255, 0.9);",
			       }}
			>
				<Box sx={{backgroundColor: "#FECE00", width: "100%", height: 3}}/>
				<Typography variant={isMobile ? "h5" : "h4"} sx={{
					pt: isMobile ? "unset" : "5px",
					[theme.breakpoints.down("sm")]: isIos && {
						'@media (-webkit-min-device-pixel-ratio: 2)': {
							fontWeight: 500,
						},
					},
				}}>
					{person.firstName}
				</Typography>
				<Typography variant={isMobile ? "h5" : "h4"} sx={{
					[theme.breakpoints.down("sm")]: isIos && {
						'@media (-webkit-min-device-pixel-ratio: 2)': {
							fontWeight: 500,
						},
					},
				}}>
					{person.lastName}
				</Typography>
			</Stack>
		</CardWithShadow>
	);
} 