import {alpha, Box, CardContent, Stack, Typography, useTheme} from "@mui/material";
import {CardFooter, CardOverImageTitle, CardWithShadow} from "@nitra/nkmb-card-components";
import useIosContext from "@nitra/nkmb-card-components/contexts/IosContextProvider";
import {NkmbIconFax, NkmbIconLokacija, NkmbIconMail, NkmbIconTel} from "@nitra/nkmb-theme";
import React from "react";
import {useTranslation} from "react-i18next";
import {IconAndTextLineDesktop} from "./IconAndTextLineDesktop";
import {OpeningTimeDesktopBox} from "./OpeningTimeDesktopBox";

export function MoreContactMobile() {
	const {isIos} = useIosContext()
	const theme = useTheme();
	const {t} = useTranslation();

	return (
		<Stack sx={{pt: 5}}>
			<Typography variant={"h5"} sx={{
				[theme.breakpoints.down("sm")]: isIos && {
					'@media (-webkit-min-device-pixel-ratio: 2)': {
						fontWeight: 500,
					},
				},
			}}>{t('more.contact.clubName')}</Typography>
			<IconAndTextLineDesktop icon={NkmbIconTel} text={"info@nkmaribor.com"}/>
			<IconAndTextLineDesktop icon={NkmbIconLokacija}
			                        text={t('more.contact.clubAddress')}/>
			<IconAndTextLineDesktop icon={NkmbIconMail} text={"02 / 228 47 00"}/>
			<IconAndTextLineDesktop icon={NkmbIconMail} text={"02 / 228 47 11"}/>
			<IconAndTextLineDesktop icon={NkmbIconFax} text={"02 / 228 47 01"}/>
			<Box sx={{
				width: "90%",
				backgroundColor: alpha("#676767", 0.2),
				my: 3,
				height: "1px",
			}}/>
			<Typography variant={"h5"} sx={{
				pb: 2,
				[theme.breakpoints.down("sm")]: isIos && {
					'@media (-webkit-min-device-pixel-ratio: 2)': {
						fontWeight: 500,
					},
				},
			}}>{t('more.contact.violetCottage')}</Typography>
			<IconAndTextLineDesktop icon={NkmbIconTel} text={"trgovina@nkmaribor.com"}/>
			<Typography sx={{pb: 1, pt: 2}}
			            variant={"podnaslovS"}>{t('more.contact.locations.mercatorTabor')}</Typography>
			<IconAndTextLineDesktop icon={NkmbIconLokacija} text={t('more.contact.locations.mercatorTaborAddress')}/>
			<IconAndTextLineDesktop icon={NkmbIconMail} text={"02 / 620 96 66"}/>
			<OpeningTimeDesktopBox days={[
				t('more.contact.days.monToSat'),
				t('more.contact.days.sunAndHolidays')
			]} times={[
				t('more.contact.times.8to20'),
				t('more.contact.closed')
			]}/>
			<Typography sx={{pb: 1, pt: 2}} variant={"podnaslovS"}>{t('more.contact.cityCenter')}</Typography>
			<IconAndTextLineDesktop icon={NkmbIconLokacija} text={t('more.contact.locations.cityAddress')}/>
			<IconAndTextLineDesktop icon={NkmbIconMail} text={"02 / 620 86 96"}/>
			<OpeningTimeDesktopBox days={[
				t('more.contact.days.monToFri'),
				t('more.contact.days.sat'),
				t('more.contact.days.sunAndHolidays')
			]} times={[
				t('more.contact.times.9to19'),
				t('more.contact.times.9to14'),
				t('more.contact.closed')
			]}/>
			<CardWithShadow
				sx={{display: "flex", flexDirection: "column", position: "relative", width: "calc(100%-32px)", mt: 2}}
				to={"https://www.vijolcnabajta.si/si.html"}
				cardHeightOverride={230}
				fullSizeImageUrl={"/images/more/contact/bajta.png"}
			>
				<CardContent sx={{zIndex: 1, mt: "auto"}}>
					<CardOverImageTitle title={t('more.contact.violetCottageTitle')} variant={"podnaslovM"}/>
				</CardContent>
				<CardFooter brightText={true} shareUrl={"https://www.vijolcnabajta.si/si.html"}/>

			</CardWithShadow>
			<Box sx={{
				width: "90%",
				backgroundColor: alpha("#676767", 0.2),
				my: 3,
				height: "1px",
			}}/>
			<Typography variant={"h5"} sx={{
				pb: 1,
				[theme.breakpoints.down("sm")]: isIos && {
					'@media (-webkit-min-device-pixel-ratio: 2)': {
						fontWeight: 500,
					},
				},
			}}>{t('more.contact.hairdresser')}</Typography>
			<IconAndTextLineDesktop icon={NkmbIconTel} text={"salon@nkmaribor.com"}/>
			<IconAndTextLineDesktop icon={NkmbIconLokacija}
			                        text={"Mercator center Tabor II, Ulica Eve Lovše 1"}/>
			<IconAndTextLineDesktop icon={NkmbIconMail} text={"02 / 620 96 67"}/>
			<Stack sx={{flexGrow: 1}}>
				<OpeningTimeDesktopBox days={[
					t('more.contact.days.monToSat'),
					t('more.contact.days.sunAndHolidays')
				]} times={[
					t('more.contact.times.8to20'),
					t('more.contact.closed')
				]}/>
			</Stack>
			<CardWithShadow
				sx={{display: "flex", flexDirection: "column", position: "relative", width: "calc(100%-32px)", mt: 2}}

				cardHeightOverride={230}
				to={"https://www.facebook.com/FrizerskaBajta/?locale=sl_SI"}
				fullSizeImageUrl={"/images/more/contact/frizer.png"}
			>
				<CardContent sx={{zIndex: 1, mt: "auto"}}>
					<CardOverImageTitle title={t('more.contact.hairdresserTitle')} variant={"podnaslovM"}/>
				</CardContent>
				<CardFooter brightText={true} shareUrl={"https://www.facebook.com/FrizerskaBajta/?locale=sl_SI"}/>
			</CardWithShadow>
		</Stack>
	);
}