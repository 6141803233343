import {Grid, Stack} from "@mui/material";
import {
	ArticleListFirestoreDocument,
	CalendarCardWithLogic,
	MatchCardDto,
	MatchListElementFirestoreDocument,
	ShopItemCardDto,
	TimeManagementContextProvider,
	useIsMobileContext
} from "@nitra/nkmb-card-components";
import {getLanding, getSeasonMatches} from "firestore/BaseFirestore";
import {useEffect, useState} from "react";
import {useTranslation} from 'react-i18next';
import {VideoFirestoreListDocument} from "../models";
import BannerFirebaseDocument from "../models/firestore/common/BannerFirebaseDocument";
import RandomPlayerFirebaseDocument from "../models/firestore/common/RandomPlayerFirestoreDocument";
import {LandingNewsContainer} from "./landing/LandingNewsContainer";
import LandingMatches from "./permanentLandingComponents/LandingMatches";
import LandingPermanentBottomCards from "./permanentLandingComponents/LandingPermanentBottomCards";
import LandingShopRow from "./permanentLandingComponents/LandingShopRow";
import {LandingTopBanner} from "./permanentLandingComponents/LandingTopBanner";
import LandingVpsBanner from "./permanentLandingComponents/LandingVpsBanner";
import NextMatchBanner from "./permanentLandingComponents/NextMatchBanner";

const Landing = () => {
	const {t, i18n} = useTranslation();
	const [cards, setCards] = useState<ArticleListFirestoreDocument[]>([]);
	const [matches, setMatches] = useState<MatchCardDto[]>([]);
	const [products, setProducts] = useState<ShopItemCardDto[]>([]);
	const [topBanner, setTopBanner] = useState<BannerFirebaseDocument>();
	const [promotedMatchBanner, setPromotedMatchBanner] = useState<MatchListElementFirestoreDocument>();
	const [randomPlayer, setRandomPlayer] = useState<RandomPlayerFirebaseDocument>();
	const [video, setVideo] = useState<VideoFirestoreListDocument>();
	const [error, setError] = useState<string | null>(null);

	const isMobile = useIsMobileContext();

	useEffect(() => {
		getLanding().then(
			(data) => {
				setCards(data.news);

				setMatches(data.matches);
				setProducts(data.products);

				if (data.topBanner) {
					setTopBanner({
						...data.topBanner,
						title: data.topBanner.title,
					} as BannerFirebaseDocument);
				}

				setPromotedMatchBanner(data.promotedMatchBanner);
				setRandomPlayer(data.randomPlayer);
				setVideo(data.video);
			},
			(e) => {
				console.error(e);
				setError(t('landing.errors.loadingFailed'));
			}
		);
	}, [i18n.language, t]);

	if (error) {
		return <div>{error}</div>;
	}

	return (
		<Stack
			alignItems={"center"}
			direction={"column"}
			sx={{display: "flex", flexGrow: 1, width: "100%"}}
		>
			<LandingTopBanner data={topBanner}/>
			<LandingNewsContainer items={cards} video={video}/>
			{promotedMatchBanner && <NextMatchBanner data={promotedMatchBanner}/>}
			<LandingMatches matches={matches}/>
			{isMobile &&
                <Grid item xs={12} sx={{minWidth: "300px", width: "100%", px: 2}}>
                    <TimeManagementContextProvider
                        getterFunction={getSeasonMatches}
                        limitToCurrentSeason={true}
                    >
                        <CalendarCardWithLogic/>
                    </TimeManagementContextProvider>
                </Grid>
			}
			<LandingShopRow shopItems={products}/>
			<LandingVpsBanner/>
			<LandingPermanentBottomCards randomPlayer={randomPlayer}/>
		</Stack>
	);
};

export default Landing;
