import {ButtonTabNavigatorButtons} from "@nitra/nkmb-card-components";
import React from "react";

const tabs = [{
	label: {en: "Emblem", sl: "Grb"},
	path: "emblem",
}, {
	label: {en: "Colors", sl: "Barve"},
	path: "colors",
}, {
	label: {en: "Region", sl: "Regija"},
	path: "region",
}]

export function ClubSymbolNavigationButtons() {
	return (
		<ButtonTabNavigatorButtons
			tabs={tabs}
			replaceLastPath={true}
		/>
	)
}
