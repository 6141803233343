import {alpha, Box, useTheme} from "@mui/material";
import {useIsMobileContext} from "@nitra/nkmb-card-components";
import React from "react";
import {Link} from "react-router-dom";

export const AppBarLogo = () => {
	const theme = useTheme()

	const isMobile = useIsMobileContext()

	return (
		<Box
			component={Link}
			to={"/"}
			sx={{
				flexShrink: 0,
				display: "flex",
				height: "100%",
				alignItems: "center",
				'&:hover': {
					backgroundColor: alpha(theme.palette.common.white, 0.07),
					cursor: "pointer",
				}
			}}>
			<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 187.09 232.47"
			     style={{
				     width: isMobile ? "36px" : "48px",
				     paddingInline: "16px",
			     }}
			     fill={"#ffcb05"}>
				<path
					d="M94.85,54.61C35.83,54.61.06,78.14.06,78.14c0,0-5.86,90.25,94.12,154.33,0,0,92.9-53.24,92.9-154.47-33.22-23.71-92.24-23.38-92.24-23.38ZM94.18,230.02C-.55,168.9,5.26,81.5,5.26,81.5c0,0,33.31-22.31,89.23-22.31,0,0,55.92-.31,87.4,22.31,0,96.57-87.71,148.52-87.71,148.52Z"/>
				<path
					d="M94.18,81.81c-55.92,0-80.98,19.25-80.98,19.25,0,0-2.14,74.26,80.98,125.9,0,0,75.18-42.78,80.06-125.6-29.95-21.39-80.06-19.56-80.06-19.56ZM137.48,165.85s-8.66,5.73-19.2,9.24v4.13s9.93,5.81,7.03,15.43c0,0-2.75,9.17-13.9,8.4-4.34-.3-7.36-3.92-11.16-5.56-4.13-1.78-8.88-1.84-12.98.06l.1-.06c-3.81,1.65-6.82,5.27-11.16,5.56-11.16.76-13.91-8.4-13.91-8.4-2.9-9.63,7.03-15.43,7.03-15.43v-4.13c-10.54-3.51-19.2-9.24-19.2-9.24v-.08c-34.23-25.06-18.64-39.42-18.64-39.42,15.89-12.83,36.37,7.95,36.37,7.95l3.05-2.9s-7.94-28.11,6.88-38.35c0,0,15.72-11.48,31.91-.3,14.82,10.23,7,38.73,7,38.73l3.05,2.9s20.48-20.78,36.37-7.95c0,0,15.59,14.36-18.64,39.42Z"/>
				<polygon
					points="24.15 91.56 20.82 83.7 20.85 83.69 30.37 89.19 32 88.5 26.98 76.7 24.73 77.66 28.03 85.43 28 85.45 18.48 79.95 16.85 80.64 21.9 92.52 24.15 91.56"/>
				<polygon
					points="36.47 86.79 34.77 81.4 34.81 81.39 40.33 85.58 43.34 84.64 36.51 79.69 39.32 72.73 36.44 73.63 34.32 79.85 34.29 79.86 32.7 74.81 30.36 75.54 34.13 87.53 36.47 86.79"/>
				<polygon
					points="51.69 82.65 51.34 74.78 51.37 74.77 55.55 82.09 56.39 81.93 57.71 73.58 57.74 73.57 60.17 81.05 62.6 80.59 58.35 68.27 56.62 68.59 55.24 77.28 50.65 69.72 48.95 70.04 49.26 83.11 51.69 82.65"/>
				<path
					d="M66.77,77.46l4.34-.5,1.26,2.52,2.6-.3-6.2-12.27-1.89.22-3.4,13.37,2.57-.29.73-2.75ZM68.1,70.7h.03s2.08,4.36,2.08,4.36l-2.96.34.86-4.69Z"/>
				<path
					d="M78.72,78.98l-.31-5.06h.03s3.36,4.87,3.36,4.87l2.96-.18-3.87-5.07c.41-.09.76-.26,1.07-.5.31-.24.56-.53.76-.86.2-.33.34-.69.43-1.09.09-.39.12-.79.09-1.19-.04-.67-.18-1.23-.41-1.69-.23-.46-.54-.84-.93-1.12-.38-.28-.84-.48-1.36-.59-.52-.11-1.1-.15-1.72-.11l-3.31.2.77,12.54,2.45-.15ZM78.41,68.42c.32-.02.62-.01.9.02.28.03.53.11.74.23.21.12.39.3.52.54.13.24.21.54.23.92.02.39-.01.71-.11.96-.1.25-.24.46-.44.62-.2.16-.43.28-.71.35-.28.07-.59.12-.92.14l-.32.02-.23-3.78.33-.02Z"/>
				<rect x="85.94" y="66.01" width="2.45" height="12.57"
				      transform="translate(-2.05 2.54) rotate(-1.65)"/>
				<path
					d="M95.09,78.45c.57-.01,1.09-.09,1.59-.24.49-.15.91-.37,1.26-.67.35-.3.62-.68.82-1.12.2-.45.29-.98.28-1.61,0-.34-.05-.69-.15-1.03-.09-.34-.23-.66-.41-.95-.18-.29-.42-.54-.69-.74-.28-.2-.6-.33-.97-.39.38-.26.66-.62.82-1.08.16-.46.24-.92.23-1.37-.01-.62-.11-1.15-.29-1.58-.18-.43-.44-.78-.77-1.04-.33-.27-.73-.46-1.2-.57-.47-.11-.99-.16-1.57-.15l-3.05.06.25,12.56,3.85-.08ZM95.73,73.21c.25.11.45.27.6.5.15.22.24.54.24.95,0,.41-.05.74-.18.98-.13.24-.3.43-.52.55-.22.13-.48.21-.77.25-.29.04-.61.06-.94.07h-.52s-.07-3.49-.07-3.49h.4c.31-.01.62,0,.92,0,.31.02.58.08.83.18ZM93.79,67.88c.26,0,.49.02.71.06.22.05.4.13.56.25.16.12.28.28.38.49.09.21.14.47.15.78,0,.32-.02.59-.09.81-.07.22-.18.39-.32.52-.15.13-.33.21-.55.27-.22.05-.48.08-.78.09h-.3s-.06-3.26-.06-3.26h.32Z"/>
				<path
					d="M102.06,76.83c.51.63,1.14,1.14,1.88,1.53.74.39,1.57.62,2.49.66.91.05,1.76-.09,2.54-.4.78-.32,1.46-.76,2.04-1.33.58-.57,1.04-1.25,1.38-2.04.35-.79.54-1.63.59-2.53.04-.88-.06-1.72-.33-2.54-.26-.81-.65-1.54-1.16-2.17-.51-.63-1.14-1.15-1.89-1.54-.75-.4-1.58-.62-2.49-.66-.92-.05-1.77.09-2.55.4-.78.32-1.46.76-2.03,1.34-.58.58-1.04,1.26-1.38,2.04-.35.78-.54,1.61-.59,2.49-.05.9.06,1.76.33,2.58.26.82.65,1.54,1.16,2.17ZM103.46,70.62c.21-.48.48-.9.82-1.27.34-.37.75-.65,1.21-.86.47-.21.97-.3,1.5-.27.53.03,1.02.17,1.46.43.44.26.82.58,1.12.98.3.4.53.85.69,1.35.16.5.22,1,.2,1.51-.02.48-.14.96-.36,1.46-.21.5-.5.94-.85,1.35-.35.4-.76.73-1.22.98-.46.25-.96.36-1.48.33-.52-.03-1-.19-1.44-.48-.44-.3-.81-.66-1.11-1.1-.31-.44-.54-.91-.7-1.43-.16-.51-.23-1.01-.21-1.49.03-.51.14-1.01.35-1.48Z"/>
				<path
					d="M117.46,74.46h.03s2.47,5.38,2.47,5.38l2.95.33-2.94-5.66c.41-.02.79-.12,1.14-.31.34-.19.64-.42.89-.71.25-.29.45-.62.61-1,.15-.37.25-.76.3-1.16.07-.66.03-1.24-.12-1.74-.15-.5-.39-.92-.72-1.26-.33-.34-.75-.62-1.24-.82-.5-.2-1.06-.34-1.67-.4l-3.3-.36-1.38,12.49,2.44.27.56-5.04ZM118.07,69l.33.04c.32.04.62.09.89.17.27.08.5.2.69.35.19.15.33.36.42.62.09.26.11.57.07.95-.04.39-.13.69-.27.93-.14.23-.32.41-.54.54-.22.13-.47.2-.76.23-.29.02-.6.02-.93-.02l-.32-.04.42-3.76Z"/>
				<polygon
					points="135.38 82.36 137.84 70.03 133.82 69.23 133.4 71.32 135.02 71.64 132.98 81.88 135.38 82.36"/>
				<path
					d="M141.44,78.98c.39.34.86.59,1.42.73.33.09.67.11,1,.07l.02.04-3.42,2.88,1.41,1.73,4.48-3.95c.55-.49,1.07-1.04,1.54-1.65.47-.61.81-1.29,1-2.03.16-.61.2-1.2.11-1.77-.09-.57-.27-1.08-.56-1.54-.29-.46-.67-.86-1.13-1.2-.47-.34-1-.59-1.59-.74-.62-.16-1.23-.21-1.81-.13-.58.08-1.11.25-1.59.53-.48.28-.89.66-1.24,1.13-.35.48-.61,1.03-.78,1.65-.14.53-.19,1.05-.15,1.58.04.53.17,1.02.38,1.47.21.45.52.85.9,1.19ZM142.72,75.26c.07-.27.18-.53.34-.78.16-.25.35-.46.57-.63.22-.17.47-.29.74-.37.27-.07.56-.07.86,0,.3.08.55.22.75.41.2.2.36.42.47.68.11.26.18.54.2.83.02.3,0,.58-.08.85-.07.28-.19.54-.35.79-.16.24-.35.45-.58.62-.22.17-.47.29-.74.37-.27.07-.56.07-.86,0-.3-.08-.55-.22-.75-.41-.2-.2-.36-.42-.47-.68-.11-.26-.17-.53-.19-.82-.02-.29.01-.58.09-.86Z"/>
				<path
					d="M148.5,83.39c.05.57.2,1.09.45,1.57.25.48.6.91,1.03,1.28.43.37.95.66,1.54.86.61.21,1.21.3,1.79.26.59-.03,1.13-.17,1.62-.41.49-.24.93-.58,1.32-1.03.39-.44.69-.97.89-1.58.18-.52.26-1.04.26-1.57s-.09-1.03-.27-1.49c-.18-.47-.45-.89-.81-1.26-.36-.37-.81-.65-1.36-.84-.17-.06-.33-.1-.5-.13-.17-.03-.33-.04-.48-.03l-.02-.04,3.63-2.61-1.28-1.81-4.76,3.6c-.59.44-1.14.95-1.66,1.53-.52.57-.91,1.22-1.15,1.95-.21.6-.29,1.18-.24,1.75ZM151.01,82.35c.09-.26.22-.51.41-.75.18-.24.39-.43.62-.59.24-.15.49-.26.77-.31.28-.05.56-.03.86.07.29.1.53.26.72.47.19.21.32.45.42.72.09.27.13.55.12.84,0,.3-.06.58-.15.84-.09.26-.22.51-.4.74-.17.23-.38.43-.61.58-.24.15-.49.26-.77.31-.28.05-.56.03-.86-.07-.29-.1-.54-.26-.72-.47-.19-.21-.32-.45-.42-.72-.09-.27-.14-.55-.13-.84,0-.29.05-.57.14-.83Z"/>
				<path
					d="M158.87,88.68c.16.45.4.85.72,1.21.32.36.73.66,1.23.89.51.23,1,.34,1.48.34.48,0,.94-.09,1.39-.26.44-.17.87-.41,1.26-.73.4-.31.77-.66,1.1-1.05.34-.38.64-.79.91-1.22.27-.43.49-.84.68-1.25.28-.62.51-1.31.69-2.08.18-.77.24-1.52.2-2.27-.05-.75-.24-1.44-.58-2.07-.34-.64-.89-1.13-1.66-1.48-.77-.35-1.5-.44-2.21-.28-.7.16-1.35.47-1.94.93-.59.46-1.12,1.01-1.58,1.65-.46.64-.83,1.27-1.11,1.88-.18.4-.35.85-.49,1.33-.15.48-.25.98-.32,1.49-.07.51-.09,1.01-.06,1.52.03.51.12.98.29,1.43ZM160.9,86.51c.05-.34.11-.68.19-1.01.08-.33.17-.64.28-.92.11-.29.2-.52.28-.7.09-.19.2-.42.35-.68.14-.26.31-.54.51-.81.19-.28.4-.55.63-.8.23-.26.46-.47.72-.65.25-.17.5-.29.77-.35.26-.06.53-.03.79.09.26.12.46.3.59.53.13.24.21.51.25.81.03.3.03.62-.02.96-.04.34-.11.67-.19,1-.08.33-.17.63-.28.92-.1.28-.2.52-.29.71-.09.19-.2.42-.35.69-.14.26-.31.54-.51.81-.19.28-.41.55-.63.81-.23.26-.47.48-.72.65-.25.17-.51.29-.77.35-.27.06-.53.03-.79-.09-.26-.12-.46-.3-.59-.54-.13-.24-.21-.51-.24-.81-.04-.3-.03-.63.02-.97Z"/>
				<path
					d="M109.92,146.39c-4.84,0-4.89-4.43-4.89-4.43h-4.28v7.33h-12.99v-7.33h-4.28s-.1,4.34-4.79,4.43h-.1c-4.53-.19-4.43-4.43-4.43-4.43h-4.74v30.25h18.34v-14.9s5.75-6.95,12.83,0v14.9h18.34v-30.25h-4.59s-.1,4.29-4.43,4.43Z"/>
				<path
					d="M91,143.37c0,1.75,1.42,3.18,3.18,3.18s3.18-1.43,3.18-3.18c0-1-.46-1.87-1.18-2.46h.84l1.89.03v-18.82h0c0-.32-.26-.58-.57-.58h-.66c-.32,0-.57.25-.58.58h-.01v15.89h-.92v-17.93h-.02c-.04-.29-.29-.52-.59-.52h-2.86c-.3,0-.55.23-.59.52h-.02v.09h0v17.84h-.92v-15.89h-.02c-.04-.3-.29-.54-.59-.54h-.7c-.31,0-.56.24-.59.54h-.02v18.82l2.92-.02c-.72.59-1.18,1.46-1.18,2.45Z"/>
				<polygon
					points="80.4 40.44 93.54 33.53 106.68 40.44 104.17 25.81 114.8 15.45 100.11 13.31 93.54 0 86.97 13.31 72.28 15.45 82.91 25.81 80.4 40.44"/>
			</svg>
		</Box>
	)
}
