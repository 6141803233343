import BannerFirestoreDocument from "../../models/firestore/common/BannerFirebaseDocument";

export function documentSnapshotToBannerFirestoreDocument(payload: any): BannerFirestoreDocument {
	// check if this is using the old non localized version and convert it to the new one by using the value for both en and sl
	if (payload.title && typeof payload.title === "string") {
		payload.title = {
			en: payload.title,
			sl: payload.title,
		}
	}

	return {
		...payload,
		createdAt: payload.createdAt.toDate(),
	}
}