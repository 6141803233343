import {Stack} from "@mui/material";
import React from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import {TeamNavigationBar} from "./navigationBar/TeamNavigationBar";
import PlayerDetailsPage from "./playerDetailsPage/PlayerDetailsPage";
import {SupportStaffView} from "./SupportStaffView";
import {TeamSquadView} from "./TeamSquadView";
import {VotingContainer} from "./VotingContainer";

const TeamRouter = () => {
	return (
		<Stack
			alignItems={"center"}
			direction={"column"}
			sx={{
				display: "flex",
				flexGrow: 1,
				width: "100%",
			}}
		>
			<TeamNavigationBar/>
			<Stack sx={{mx: 3, width: "100%"}} direction={"column"}>
				<Routes>
					<Route path={"players"} element={<TeamSquadView/>}/>
					<Route path={"staff"} element={<SupportStaffView/>}/>
					<Route path={"playerOfTheMonth"} element={<VotingContainer/>}/>
					<Route path={"player/:key/*"} element={<PlayerDetailsPage/>}/>
					<Route path={"/"} element={<Navigate replace to={"players"}/>}/>
				</Routes>
			</Stack>
		</Stack>
	)
}


export default TeamRouter;

