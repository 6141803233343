export const VipTexts = {
	basic: {
		vps: {
			imageTitle: {
				sl: "vijol'čni premium sedež",
				en: "VIP PREMIUM SEAT"
			},
			imageUrl: "/images/hospitality/vps/vps1.png",
			link: "/hospitality/vps",
			textTitle: {
				sl: "VIJOL’ČNI PREMIUM SEDEŽ",
				en: "VIP PREMIUM SEAT"
			},
			textBody: {
				sl: "Ogled tekme z Vijol'čnih premium sedežev je prestižna in vrhunska izkušnja, ki ti omogoča uživanje v tekmi z dodatno pozornostjo na gostoljubnosti ter udobju. VIP hospitality se od klasičnega ogleda tekem razlikuje po premium okolju, ki vključuje vrhunsko kulinariko in selekcijo pijač, ki bo druženju ali mreženju v premium okolju dalo dodatno razsežnost.",
				en: "Experience a VIP premium seat as a prestigious and top-class experience, allowing you to enjoy the game with additional attention to hospitality and comfort. VIP hospitality differs from classic game viewing in that it includes top-quality catering and a selection of drinks, which will add extra dimensions to socializing or networking in a premium environment."
			},
			buttonLabel: {
				sl: "Več",
				en: "More"
			},
		},
		skybox: {
			imageTitle: {
				sl: "Zasebna loža",
				en: "Skybox"
			},
			imageUrl: "/images/hospitality/skybox/skybox1.png",
			link: "/hospitality/skybox",
			textTitle: {
				sl: "ZASEBNE LOŽE",
				en: "SKYBOX"
			},
			textBody: {
				sl: "Ogled tekme iz zasebne lože kot ekskluzivno doživetje, s spremljanjem tekme ob nadgradnji udobja z vrhunsko postrežbo in z navdušujočim pogledom v elitnem okolju. Kot priložnost za poslovna srečanja ali mreženje, praznovanja v ožjem krogu ali pogostitev poslovnih partnerjev.",
				en: "Experience a private box as an exclusive experience, with the game being followed with upgraded comfort with top-notch catering and a view of the elite environment. As an opportunity for business meetings or networking, celebrations within a smaller circle or visits by business partners."
			},
			buttonLabel: {
				sl: "Več",
				en: "More"
			}
		}
	},
	vps: {
		banner: {
			title: {
				sl: "VIJOL'ČNI PREMIUM SEDEŽ",
				en: "VIP PREMIUM SEAT"
			},
			subtitle: {
				sl: "Premium sedeži",
				en: "Premium seats"
			}
		},
		title1: {
			sl: "Kaj vključuje Vijol'čni premium sedež:",
			en: "What is included in the VIP premium seat:"
		},
		body1: {
			sl: "⦁\tMožnost prihoda v hospitality prostor dve uri pred začetkom tekme\n" +
				"⦁\tBar s selekcijo izbranih vin, piva, koktejlov, brezalkoholnih pijač in toplih napitkov\n" +
				"⦁\tBogata, raznolika ponudba hrane\n" +
				"⦁\tPrigrizek ob polčasu »half time snack«\n" +
				"⦁\tOgled tekme z oblazinjenih sedežev\n" +
				"⦁\tRezervirano parkino mesto v neposredni bližini stadiona (ob nakupu najmanj dveh vstopnic) \n" +
				"⦁\tPoseben vhod\n" +
				"⦁\tPrijazno osebje\n" +
				"⦁\tDJ, ki bo poskrbel za prijetno vzdušje\n",
			en: "⦁\tPossibility to arrive in the hospitality area two hours before the start of the game\n" +
				"⦁\tBar with a selection of selected wines, beers, cocktails, non-alcoholic drinks and hot drinks\n" +
				"⦁\tRich, diverse offer of food\n" +
				"⦁\tHalf-time snack »half time snack«\n" +
				"⦁\tGame viewing with frosted seats\n" +
				"⦁\tReserved parking space in the immediate vicinity of the stadium (with the purchase of at least two tickets) \n" +
				"⦁\tSpecial entrance\n" +
				"⦁\tFriendly staff\n" +
				"⦁\tDJ, who will take care of the pleasant atmosphere\n",
		},
		title2: {
			sl: "Vijol'čni premium sedež",
			en: "VIP PREMIUM SEAT"
		},
		body2: {
			sl: "Dobrodošli na tribuni Marcos Tavares, kjer si boste tekmo ogledali na vijol’čnih premium sedežih. Nakup vstopnice VPS (Vijol'čni premium sedež) omogoča najboljše nogometno doživetje v Sloveniji, z vstopom v skupni pogostitveni prostor dve uri pred začetkom posamezne tekme. Dva polčasa s kulinaričnim podaljškom kot zabava za prijatelje, družine, sodelavce ali poslovne partnerje. ⦁ Sezonska vstopnica VPS: velja za tekme NK Maribor v Ljudskem v državnem prvenstvu in v pokalnem tekmovanju. Več informacij na hospitality@nkmaribor.com ali po telefonu 02 / 228 47 07.\n" +
				"Možnost nakupa vstopnice VPS za posamično tekmo.",
			en: "Welcome to the Marcos Tavares tribune, where you will watch the game on VIP premium seats. Buying a VIP premium seat ticket (VIP premium seat) allows you to experience the best football experience in Slovenia, with access to the common hospitality area two hours before the start of each match. Two halves with a kulinarični podaljšek as entertainment for friends, family, colleagues or business partners. ⦁ Seasonal VIP premium seat ticket: valid for NK Maribor games in the national championship and cup competition. More information at hospitality@nkmaribor.com or by phone 02 / 228 47 07.\n" +
				"Possibility to buy a VIP premium seat ticket for a single match.",
		},
		imageUrls: [
			"/images/hospitality/vps/Rectangle 63.jpg",
			"/images/hospitality/vps/Rectangle 64.jpg",
			"/images/hospitality/vps/Rectangle 65.jpg",
			"/images/hospitality/vps/Rectangle 66.jpg",
			"/images/hospitality/vps/Rectangle 67.jpg",
			"/images/hospitality/vps/Rectangle 68.jpg",
			"/images/hospitality/vps/Rectangle 69.jpg",
			"/images/hospitality/vps/Rectangle 70.jpg",
			"/images/hospitality/vps/Rectangle 71.jpg",
			"/images/hospitality/vps/Rectangle 72.jpg",
		],
		mobileImageUrls: [
			"/images/hospitality/vps/mobile/vps1.png",
			"/images/hospitality/vps/mobile/vps2.png",
			"/images/hospitality/vps/mobile/vps3.png",
			"/images/hospitality/vps/mobile/vps4.png",
			"/images/hospitality/vps/mobile/vps5.png",
			"/images/hospitality/vps/mobile/vps6.png",
			"/images/hospitality/vps/mobile/vps7.png",
			"/images/hospitality/vps/mobile/vps8.png",
			"/images/hospitality/vps/mobile/vps9.png",
			"/images/hospitality/vps/mobile/vps10.png",
		]
	},
	skybox: {
		banner: {
			title: {
				sl: "Zasebne lože",
				en: "SKYBOX"
			},
			subtitle: {
				sl: "Zasebne lože",
				en: "SKYBOX"
			}
		},
		title1: {
			sl: "Ekskluzivno doživetje v zasebni loži:",
			en: "Exclusive experience in a private box:"
		},
		body1: {
			sl: "⦁\togled tekme z oblazinjenih sedežev na najbolj ekskluzivnem delu stadiona\n" +
				"⦁\tšampanjec/aperitiv ob prihodu\n" +
				"⦁\tsoba s pogledom: zasebna pogostitev v elegantnem okolju za 12 oseb\n" +
				"⦁\tvrhunska kulinarika: kosilo ali večerja \n" +
				"⦁\tosebna selekcija: izbira vina, piva, žganih in brezalkoholnih pijač ter toplih napitkov\n" +
				"⦁\tparkirišče za 6 avtomobilov, poseben vhod, VIP dvigalo\n" +
				"⦁\tusposobljeno in predano osebje\n" +
				"⦁\tNK Maribor premium darilo za vsakega gosta\n" +
				"⦁\tWi-Fi + HD TV-zaslon\n",
			en: "⦁\tGame viewing with frosted seats on the most exclusive part of the stadium\n" +
				"⦁\tChampagne/aperitif upon arrival\n" +
				"⦁\tPrivate seating for 12 people\n" +
				"⦁\tTop-quality catering: dinner or evening meal\n" +
				"⦁\tPersonal selection: choice of wine, beer, grilled and non-alcoholic drinks and hot drinks\n" +
				"⦁\tParking for 6 cars, special entrance, VIP elevator\n" +
				"⦁\tExperienced and attentive staff\n" +
				"⦁\tNK Maribor premium gift for each guest\n" +
				"⦁\tWi-Fi + HD TV-screen\n",
		},
		title2: {
			sl: "Zasebne lože",
			en: "SKYBOX"
		},
		body2: {
			sl: "Vstop na stadion, prihod na igrišče, naboj tekme pri vsakem športniku sprožijo adrenalin posebne vrste. Nič drugačni ne bodo občutki gostov, ki bodo vstopili v elitne suite Ljudskega vrta. Kar je za nogometaše igrišče, je za gledalce zasebna loža. Privilegij med, a tudi pred tekmo in po tekmi. Vaša ekipa bo imela ves čas poseben status. Vaši gostje bodo ostali zmagovalci dneva.\n" +
				"⦁" +
				"Zasebna loža, ki sprejme 12 gostov, z ekskluzivnimi zasebnimi sedeži na balkonu, ponuja vrhunec VIP doživetij na tekmah, saj združuje vrhunsko dogajanje na igrišču z razkošjem brez primere. Priložnost za skupine, tiste, ki iščete dodatno možnost ekskluzivnega dogodka, saj ponuja izvrstno kulinariko pred tekmo in bogato selekcijo pijač med celotnim dogodkom – pred tekmo, ob polčasu ter po zadnjem sodniškem žvižgu.\n" +
				"⦁" +
				"Kot nepozaben pridih bo vsak gost prejel darilo, kot trajen spomin na nepozabno izkušnjo spremljanja tekem iz zasebne lože.\n" +
				"⦁" +
				"Najem za posamezno tekmo ali za celotno sezono (velja za tekme NK Maribor v Ljudskem v državnem prvenstvu in v pokalnem tekmovanju, a tudi v evropskih pokalih). Več informacij na hospitality@nkmaribor.com ali po telefonu 02 / 228 47 07.\n",
			en: "Entry, arrival on the field, a goal at every athlete triggers adrenaline of a special kind. The guests will not be any different. They will enter the elite suite of the Ljudski vrt. What is the field for the football players, is the private box for the spectators. Privileges during, as well as before and after the game. Your team will have a special status all the time. Your guests will remain winners of the day.\n" +
				"⦁" +
				"Private box, which can accommodate 12 guests, with exclusive private seating on the balcony, offers the peak of VIP experiences during the game, as it combines the excitement of the field with the luxury of a unique experience. Opportunity for groups, those who seek an additional opportunity for an exclusive event, as it offers top-quality catering before the game and a rich selection of drinks during the entire event – before the game, at half-time and after the final whistle.\n" +
				"⦁" +
				"As a memorable highlight, each guest will receive a gift, as a lasting memory of an unforgettable experience of watching the game from a private box.\n" +
				"⦁" +
				"Hire for a single match or for the entire season (valid for NK Maribor games in the national championship and cup competition, as well as in European cups). More information at hospitality@nkmaribor.com or by phone 02 / 228 47 07.\n",
		},
		imageUrls: [
			"/images/hospitality/skybox/Rectangle 65.png",
			"/images/hospitality/skybox/Rectangle 66.png",
			"/images/hospitality/skybox/Rectangle 67.png",
			"/images/hospitality/skybox/Rectangle 73.png",
			"/images/hospitality/skybox/Rectangle 74.png",
			"/images/hospitality/skybox/Rectangle 75.png",
			"/images/hospitality/skybox/Rectangle 76.png",
		],
		mobileImageUrls: [
			"/images/hospitality/skybox/mobile/skybox1.png",
			"/images/hospitality/skybox/mobile/skybox2.png",
			"/images/hospitality/skybox/mobile/skybox3.png",
			"/images/hospitality/skybox/mobile/skybox4.png",
			"/images/hospitality/skybox/mobile/skybox5.png",
			"/images/hospitality/skybox/mobile/skybox6.png",
			"/images/hospitality/skybox/mobile/skybox7.png",
		]
	}
}
