import {Box, Typography} from "@mui/material";
import {getLocalizedString} from "@nitra/nkmb-card-components";
import {TextContentElementPageCacheFirebaseDocument} from "models";
import {useTranslation} from "react-i18next";

export function TextContent(props: { element: TextContentElementPageCacheFirebaseDocument }) {
	const {i18n} = useTranslation();
	const currentLang = i18n.language;

	const text = getLocalizedString(props.element.text, currentLang);

	const replaceTags = (html: string) => {
		const replacements = [
			{from: /<h1(.*?)>(.*?)<\/h1>/g, to: '<div data-replace="bodyNaslovBold">$2</div>'},
			{from: /<h2(.*?)>(.*?)<\/h2>/g, to: '<div data-replace="bodyXL">$2</div>'},
			{from: /<h3(.*?)>(.*?)<\/h3>/g, to: '<div data-replace="bodyXLBold">$2</div>'},
		];

		let result = html;
		replacements.forEach(({from, to}) => {
			result = result.replace(from, to);
		});
		// if there are no replacements wrap the whole string in a bodyXL
		if (result === html) {
			result = `<div data-replace="bodyXL">${result}</div>`;
		}

		return result;
	};

	const transformedHtml = replaceTags(text);

	const renderHtml = () => {
		const parser = new DOMParser();
		const doc = parser.parseFromString(transformedHtml, 'text/html');
		const elements = doc.body.childNodes;
		const reactElements: JSX.Element[] = [];

		elements.forEach((element, index) => {
			const tag = element as HTMLElement;
			switch (tag.getAttribute('data-replace')) {
				case 'bodyNaslovBold':
					reactElements.push(
						<Typography key={index} variant="bodyNaslovBold">
							<div dangerouslySetInnerHTML={{__html: tag.innerHTML}}/>
						</Typography>
					);
					break;
				case 'bodyXL':
					reactElements.push(
						<Typography key={index} variant="bodyXL">
							<div dangerouslySetInnerHTML={{__html: tag.innerHTML}}/>
						</Typography>
					);
					break;
				case 'bodyXLBold':
					reactElements.push(
						<Typography key={index} variant="bodyXLBold">
							<div dangerouslySetInnerHTML={{__html: tag.innerHTML}}/>
						</Typography>
					);
					break;
				default:
					reactElements.push(<div key={index} dangerouslySetInnerHTML={{__html: tag.outerHTML}}/>);
			}
		});

		return reactElements;
	};


	return (
		<Box sx={{
			px: 2
		}}>
			{renderHtml()}
		</Box>
	)
}
