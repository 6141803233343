import {
	SeasonTrophiesFirestoreDocument,
	SeasonTrophiesListContainerFirestoreDocument
} from "@nitra/nkmb-card-components";
import {collection, doc, getDoc} from "firebase/firestore";
import {db} from "../BaseFirestore";
import {mapAndSortContent} from "../mappers/ContentMapper";

export async function getSeasonTrophiesList(): Promise<SeasonTrophiesListContainerFirestoreDocument> {
	const snapshot = await getDoc(doc(collection(db, 'seasonTrophies/'), "list"));

	if (!snapshot.exists()) throw new Error('Season trophies list not found');

	// check if this is using the old non localized version and convert it to the new one by using the value for both en and sl
	return {
		nzs: snapshot.data().nzs.map((doc: any) => {
			if (doc.cardTitle && typeof doc.cardTitle === 'string') {
				doc.cardTitle = {
					en: doc.cardTitle,
					sl: doc.cardTitle,
				}
			}
			return doc
		}),
		uefa: snapshot.data().uefa.map((doc: any) => {
			if (doc.cardTitle && typeof doc.cardTitle === 'string') {
				doc.cardTitle = {
					en: doc.cardTitle,
					sl: doc.cardTitle,
				}
			}
			return doc
		}),
		mobile: snapshot.data().mobile.map((doc: any) => {
			if (doc.cardTitle && typeof doc.cardTitle === 'string') {
				doc.cardTitle = {
					en: doc.cardTitle,
					sl: doc.cardTitle,
				}
			}
			return doc
		}),

		leagueWinnerCount: snapshot.data().leagueWinnerCount,
		cupWinnerCount: snapshot.data().cupWinnerCount,
		superCupWinnerCount: snapshot.data().superCupWinnerCount,
	} as SeasonTrophiesListContainerFirestoreDocument;
}

export async function getSeasonTrophiesText(competition: string, key: string): Promise<SeasonTrophiesFirestoreDocument> {
	const snapshot = await getDoc(doc(collection(db, `seasonTrophies/${competition}/seasonTrophies/`), key));

	if (!snapshot.exists()) throw new Error('Season trophies not found');
	// check if this is using the old non localized version and convert it to the new one by using the value for both en and sl
	if (snapshot.data().title && typeof snapshot.data().title === 'string') {
		snapshot.data().title = {
			en: snapshot.data().title,
			sl: snapshot.data().title,
		}
	}

	if (snapshot.data().subtitle && typeof snapshot.data().subtitle === 'string') {
		snapshot.data().subtitle = {
			en: snapshot.data().subtitle,
			sl: snapshot.data().subtitle,
		}
	}

	return {
		key: snapshot.data().key,
		createdAt: snapshot.data().createdAt.toDate(),
		updatedAt: snapshot.data().updatedAt.toDate(),
		season: snapshot.data().season,
		title: snapshot.data().title,
		subtitle: snapshot.data().subtitle,
		image1440x680url: snapshot.data().image1440x680url,
		image720x540url: snapshot.data().image720x540url,
		content: mapAndSortContent(snapshot.data().content),
	} as SeasonTrophiesFirestoreDocument;
}