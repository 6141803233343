import {Grid, Stack} from "@mui/material";
import {LocalizedStringDto, useIsMobileContext} from "@nitra/nkmb-card-components";
import {ContentContainer} from "components/ContentRender/ContentContainer";
import {getLegend} from "firestore/BaseFirestore";
import {
	ContentElementPageCacheFirebaseDocumentUnion
} from "models/firestore/pageCache/article/ContentElementPageCacheFirebaseDocument";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import Banner from "../../../components/Banner";
import {EPlayerPosition} from "../../../models";
import {
	PlayerAgeCard,
	PlayerAppearancesCard,
	PlayerBirthPlaceAndDateCard,
	PlayerGoalsCard,
	PlayerHeightCard,
	PlayerPositionCard
} from "../../team/playerDetailsPage/PlayerProfileStatCardContainer";

export function LegendProfile() {
	const [content, setContent] = useState<
		ContentElementPageCacheFirebaseDocumentUnion[]
	>([]);

	const [bannerName, setBannerName] = useState<LocalizedStringDto | undefined>(undefined);
	const [bannerUrl, setBannerUrl] = useState("");

	const [playerPosition, setPlayerPosition] = useState<EPlayerPosition | undefined>();
	const [dateOfBirth, setDateOfBirth] = useState<Date | undefined>();
	const [appearanceCount, setAppearanceCount] = useState<number | undefined>();
	const [goalCount, setGoalCount] = useState<number | undefined>();
	const [height, setHeight] = useState<number | undefined>();
	const [age, setAge] = useState<number | undefined>();


	const [image720x540url, setImage720x540url] = useState("");

	const {key} = useParams();
	const isMobile = useIsMobileContext()

	const getData = (_key: string) => {
		getLegend(_key).then((legend) => {
			setContent(legend.content);
			setBannerName({en: legend.name, sl: legend.name});
			setBannerUrl(legend.image1440x680url);
			setImage720x540url(legend.image720x540url ?? "");


			setPlayerPosition(legend.playerPosition);
			setDateOfBirth(legend.dateOfBirth);
			setAppearanceCount(legend.appearanceCount);
			setGoalCount(legend.goalCount);
			setHeight(legend.height);
			setAge(legend.age);

			console.log(legend)
		})
	}

	useEffect(() => {
		if (!key) {
			console.error("No key provided");
			return;
		}

		getData(key);
	}, [key])

	return (
		<Stack>
			{(isMobile && image720x540url) ?
				<Banner title={bannerName} imageUrl={image720x540url} desktopHeight={680}
				        sx={{aspectRatio: "720/540"}}/>
				:
				<Banner title={bannerName} imageUrl={bannerUrl} desktopHeight={680}/>
			}
			{
				playerPosition && dateOfBirth && appearanceCount && height && age &&
                <LegendStatCards
                    playerPosition={playerPosition}
                    dateOfBirth={dateOfBirth}
                    appearanceCount={appearanceCount}
                    goalCount={goalCount ?? 0}
                    height={height}
                    age={age}
                />
			}
			<ContentContainer content={content}/>
		</Stack>
	)
}

export function LegendStatCards(props: {
	playerPosition: EPlayerPosition,
	dateOfBirth: Date,
	appearanceCount: number,
	goalCount: number,
	height: number,
	age: number
}) {
	const {playerPosition, dateOfBirth, appearanceCount, goalCount, height, age} = props;

	return (
		<Grid container spacing={2} sx={{mt: 5, mx: "auto", maxWidth: "735px"}}>
			<PlayerPositionCard value={playerPosition}/>
			<PlayerBirthPlaceAndDateCard location={""} date={dateOfBirth}/>
			<PlayerAppearancesCard value={appearanceCount}/>
			{
				playerPosition !== EPlayerPosition.GOALKEEPER && <PlayerGoalsCard value={goalCount}/>
			}
			<PlayerAgeCard value={age}/>
			<PlayerHeightCard value={height}/>
		</Grid>
	)
}
