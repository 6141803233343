import {Box, Stack, Typography} from "@mui/material";
import {
	ButtonTabNavigatorWithRouter,
	DecadeCard,
	DecadeListFirestoreDocument,
	LegendCard,
	LegendListFirestoreDocument,
	useIsMobileContext,
	WarriorCard,
	WarriorListFirestoreDocument
} from "@nitra/nkmb-card-components";
import {CardContainerWithName} from "components/CardContainerWithName";
import {CardContainerWithoutName} from "components/CardContainerWithoutName";
import {InnerWidthContainer} from "components/widthControlElements";
import {getClubLegendWarriorDecade} from "firestore/BaseFirestore";
import React, {useEffect} from "react";
import {ClubBanner} from "../ClubBanner";

export function ClubHistory() {
	const [legends, setLegends] = React.useState<LegendListFirestoreDocument[]>([]);
	const [warriors, setWarriors] = React.useState<WarriorListFirestoreDocument[]>([]);
	const [decades, setDecades] = React.useState<DecadeListFirestoreDocument[]>([]);

	const isMobile = useIsMobileContext()

	const fetchData = () => {
		getClubLegendWarriorDecade().then((payload) => {
			setLegends(payload.legends);
			setWarriors(payload.warriors);
			setDecades(payload.decades);
		})
	}

	useEffect(() => {
		fetchData()
	}, [])

	const tabs = [{
		label: {en: "Legends", sl: "Legende"},
		path: "legends",
		component: <>
			<CardContainerWithName title={"Legende - upokojeni dresi"} cardAlignLeft={true}>
				{legends
					.filter(legend => legend.jerseyRetiredPermanent)
					.sort((a, b) => a.sortIndex - b.sortIndex)
					.map((legend, index) =>
						<LegendCard key={index} data={legend}/>
					)}
			</CardContainerWithName>
			<CardContainerWithName title={"Legende - upokojeni dresi za 10 let"} cardAlignLeft={true}>
				{legends
					.filter(legend => legend.jerseyRetiredTemporary)
					.sort((a, b) => a.sortIndex - b.sortIndex)
					.map((legend, index) =>
						<LegendCard key={index} data={legend}/>
					)}
			</CardContainerWithName>
			<CardContainerWithName title={"Igralci z največ nastopi v posameznih desetletjih"} cardAlignLeft={true}>
				{legends
					.filter(legend => !legend.jerseyRetiredPermanent && !legend.jerseyRetiredTemporary && !legend.isTrophyWinningCoach && !legend.isHistoricCoach)
					.sort((a, b) => a.sortIndex - b.sortIndex)
					.map((legend, index) =>
						<LegendCard key={index} data={legend}/>
					)}
			</CardContainerWithName>
			<CardContainerWithName title={"Trenerji z osvojenimi naslovi prvakov"} cardAlignLeft={true}>
				{legends
					.filter(legend => legend.isTrophyWinningCoach)
					.sort((a, b) => a.sortIndex - b.sortIndex)
					.map((legend, index) =>
						<LegendCard key={index} data={legend}/>
					)}
			</CardContainerWithName>
			<CardContainerWithName title={"Legendarni trenerji iz zgodovine"} cardAlignLeft={true}>
				{legends
					.filter(legend => legend.isHistoricCoach)
					.sort((a, b) => a.sortIndex - b.sortIndex)
					.map((legend, index) =>
						<LegendCard key={index} data={legend}/>
					)}
			</CardContainerWithName>
		</>
	}, {
		label: {en: "Vijol'čni bojevnik", sl: "Vijol'čni bojevnik"},
		path: "warriors",
		component: <Stack>
			<Box sx={{pt: 5}}>
				<Typography variant={"bodyXL"}>
					Trud nogometašev skušamo ovrednotiti na različne načine. Vsi želijo na igrišču najboljše za
					uresničitev klubskih ciljev, za dodaten motiv pa poskrbi tudi posebna nagrada, namenjena najbolj
					srčnemu posamezniku.
					<br/>
					<br/>
					Akcijo, ki je postala stalnica, smo začeli leta 2008. Med vsemi igralci, ki so vsaj na desetih
					ligaških tekmah v prejšnji sezoni letu branili barve našega kluba, navijači z glasovanjem izberejo
					tistega, ki si z odnosom do dresa, navijačev in kluba nasploh, zasluži prestižen naziv Vijol'čni
					bojevnik. O zmagovalcu odločajo glasovi kupcev sezonskih vstopnic in obiskovalcev spletne strani <a
					href={"https://www.nkmaribor.com"}>nkmaribor.com</a> ter uradne Facebook strani NK Maribor.
					Proglasitev vedno poteka na prvi domači tekmi v koledarskem letu.
				</Typography>
			</Box>
			<CardContainerWithoutName sx={{pt: 5}}>
				{warriors
					.sort((a, b) => b.year - a.year)
					.map((warrior, index) =>
						<WarriorCard key={index} data={warrior}/>
					)}
			</CardContainerWithoutName>
		</Stack>
	}, {
		label: {en: "By Decades", sl: "Po desetletjih"},
		path: "decades",
		component:
			<CardContainerWithoutName sx={{pt: 5}}>
				{decades
					.sort((a, b) => parseInt(a.title.sl.substring(0, 4)) - parseInt(b.title.sl.substring(0, 4)))
					.map((decade, index) =>
						<DecadeCard key={index} data={decade}/>
					)}
			</CardContainerWithoutName>
	}]

	return (
		<Stack sx={{m: 0, p: 0}}>
			<ClubBanner
				url={`/images/club/banners/zgodovina${isMobile ? "_mobile" : ""}.jpg`}
				mainText={"maribor vijolčni"}
			/>
			<InnerWidthContainer>
				<ButtonTabNavigatorWithRouter tabs={tabs}/>
			</InnerWidthContainer>
		</Stack>
	)
}

