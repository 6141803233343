import {Stack, Typography} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";
import {useIsMobileContext} from "@nitra/nkmb-card-components";
import React from "react";
import {useTranslation} from "react-i18next";

export function MoreCookies() {
	const isMobile = useIsMobileContext()
	const {t} = useTranslation();

	const columns = [{
		field: "source",
		headerName: t("more.cookies.source"),
		width: 100
	}, {
		field: "name",
		headerName: t("more.cookies.name"),
		flex: 3,
		renderCell: (params: any) => {
			return <Typography sx={{textWrap: "auto"}}>{params.value}</Typography>
		}
	}, {
		field: "description",
		headerName: t("more.cookies.description"),
		flex: 3,
		renderCell: (params: any) => {
			return <Typography sx={{textWrap: "auto"}}>{params.value}</Typography>
		}
	}, {
		field: "duration",
		headerName: t("more.cookies.duration"),
		width: 100
	}]

	const rows = [{
		key: 1,
		source: t("more.cookies.table.rows.analytics.source"),
		name: t("more.cookies.table.rows.analytics.name"),
		description: t("more.cookies.table.rows.analytics.description"),
		duration: t("more.cookies.table.rows.analytics.duration")
	}, {
		key: 2,
		source: t("more.cookies.table.rows.maps.source"),
		name: t("more.cookies.table.rows.maps.name"),
		description: t("more.cookies.table.rows.maps.description"),
		duration: t("more.cookies.table.rows.maps.duration")
	}, {
		key: 3,
		source: t("more.cookies.table.rows.youtube.source"),
		name: t("more.cookies.table.rows.youtube.name"),
		description: t("more.cookies.table.rows.youtube.description"),
		duration: t("more.cookies.table.rows.youtube.duration")
	}, {
		key: 4,
		source: t("more.cookies.table.rows.facebook.source"),
		name: t("more.cookies.table.rows.facebook.name"),
		description: t("more.cookies.table.rows.facebook.description"),
		duration: t("more.cookies.table.rows.facebook.duration")
	}]

	return (
		<Stack sx={{pt: 5}}>
			<Typography variant={isMobile ? "podnaslovS" : "podnaslovL"}>{t("more.cookies.title")}</Typography>
			<Typography sx={{pt: 2}}>
				{t("more.cookies.intro1")}
			</Typography>
			<Typography sx={{pt: 2}}>
				{t("more.cookies.intro2")}
			</Typography>
			<Typography sx={{pt: 2}}>
				{t("more.cookies.intro3")}
			</Typography>
			<DataGrid columns={columns} rows={rows} pageSize={5} getRowId={(row) => row.key} autoHeight={true}
			          getRowHeight={() => "auto"}/>

			<Typography sx={{pt: 2}}>{t("more.cookies.noShare")}</Typography>
			<Typography sx={{pt: 5}}
			            variant={isMobile ? "podnaslovS" : "podnaslovL"}>{t("more.cookies.disableTitle")}</Typography>

			<Typography sx={{pt: 2}}>
				{t("more.cookies.disableText")}
			</Typography>
		</Stack>
	)
}