import {Box, Grid, Stack, Typography} from "@mui/material";
import React from "react";
import {useTranslation} from "react-i18next";
import {SponsorCategoryFirestoreListDocument, SponsorFirestoreListDocument} from "../../../models";

export function MoreSponsorsDesktop(props: {
	categories: SponsorCategoryFirestoreListDocument[],
	sponsors: SponsorFirestoreListDocument[]
}) {
	const {categories, sponsors} = props;
	const {t} = useTranslation();

	function getCategoryTranslation(level: number): string {
		switch (level) {
			case 1:
				return t('more.sponsors.mainSponsor');
			case 2:
				return t('more.sponsors.premiumPartners');
			case 3:
				return t('more.sponsors.officialPartners');
			default:
				return t('more.sponsors.partners');
		}
	}

	return (
		<Stack>
			{categories.sort((a, b) => a.level - b.level).map(category =>
				<Box
					sx={{
						pt: "70px"
					}}
					key={category.level}>
					<Typography variant={"podnaslovL"}
					            sx={{}}>{getCategoryTranslation(category.level)}</Typography>
					<Grid container spacing={1} sx={{
						pt: 4,
					}}>
						{
							sponsors
								.filter(sponsor => sponsor.level === category.level)
								.sort((a, b) => a.positionIndex - b.positionIndex)
								.map(sponsor =>
									<Grid item key={sponsor.key}
									      onClick={() => window.open(sponsor.url, "_blank")}
									      sx={{
										      width: "200px",
										      height: "200px",
										      backgroundImage: `url(${sponsor.image200x200url})`,
										      backgroundSize: "contain"
									      }}
									/>
								)
						}
					</Grid>
				</Box>
			)}
		</Stack>
	)
}