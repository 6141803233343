import {ArticleListFirestoreDocument} from "@nitra/nkmb-card-components";

export function documentSnapshotToNewsArticleCardDtoMapper(payload: any): ArticleListFirestoreDocument[] {
	return payload.map((news: any) => {
		// check if this is using the old non localized version and convert it to the new one by using the value for both en and sl
		if (news.title && typeof news.title === "string") {
			news.title = {
				en: news.title,
				sl: news.title,
			}
		}
		if (news.shortText && typeof news.shortText === "string") {
			news.shortText = {
				en: news.shortText,
				sl: news.shortText,
			}
		}

		return {
			...news,
			createdAt: news.createdAt.toDate(),
			updatedAt: news.updatedAt.toDate(),
		}
	})
}

