import {
	ContentElementPageCacheFirebaseDocument,
	EContentType,
	EmbedContentElementPageCacheFirebaseDocument,
	ImageContentElementPageCacheFirebaseDocument,
	TextContentElementPageCacheFirebaseDocument,
	VideoContentElementPageCacheFirebaseDocument
} from "models";

export function mapAndSortContent(payload: any[]): ContentElementPageCacheFirebaseDocument[] {
	return payload.map((element: ContentElementPageCacheFirebaseDocument) => {
		let data
		switch (element.type) {
			case EContentType.TEXT:
				// check if this is using the old non localized version and convert it to the new one by using the value for both en and sl
				const text = (element as TextContentElementPageCacheFirebaseDocument).text
				if (text && typeof text === "string") {
					(element as TextContentElementPageCacheFirebaseDocument).text = {
						en: text,
						sl: text
					}
				}

				data = {
					text: (element as TextContentElementPageCacheFirebaseDocument).text,
				}
				break;
			case EContentType.IMAGE:
				const imageAltText = (element as ImageContentElementPageCacheFirebaseDocument).imageAltText
				if (imageAltText && typeof imageAltText === "string") {
					(element as ImageContentElementPageCacheFirebaseDocument).imageAltText = {
						en: imageAltText,
						sl: imageAltText
					}
				}

				const imageCaption = (element as ImageContentElementPageCacheFirebaseDocument).imageCaption
				if (imageCaption && typeof imageCaption === "string") {
					(element as ImageContentElementPageCacheFirebaseDocument).imageCaption = {
						en: imageCaption,
						sl: imageCaption
					}
				}

				data = {
					imageUrl: (element as ImageContentElementPageCacheFirebaseDocument).imageUrl,
					imageAltText: (element as ImageContentElementPageCacheFirebaseDocument).imageAltText,
					imageCaption: (element as ImageContentElementPageCacheFirebaseDocument).imageCaption,
					imageCredit: (element as ImageContentElementPageCacheFirebaseDocument).imageCredit,
				}
				break;
			case EContentType.VIDEO:
				data = {
					youtubeId: (element as VideoContentElementPageCacheFirebaseDocument).youtubeId,
					videoThumbnailUrl: (element as VideoContentElementPageCacheFirebaseDocument).videoThumbnailUrl,
				}
				break;
			case EContentType.EMBED:
				data = {
					embedCode: (element as EmbedContentElementPageCacheFirebaseDocument).embedCode,
				}
				break;
			default:
				throw new Error("Unknown content type")
		}

		return {
			type: element.type,
			index: element.index,
			...data
		}
	}).sort((a: ContentElementPageCacheFirebaseDocument, b: ContentElementPageCacheFirebaseDocument) => a.index - b.index)
}
