import {Stack} from "@mui/material";
import {LocalizedStringDto, useIsMobileContext} from "@nitra/nkmb-card-components";
import Banner from "components/Banner";
import {ContentContainer} from "components/ContentRender/ContentContainer";
import {ContentElementPageCacheFirebaseDocumentUnion} from "models";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {getSeasonTrophiesText} from "../../../firestore";

export function ClubTrophy() {
	const {key, competition} = useParams()

	const [content, setContent] = useState<
		ContentElementPageCacheFirebaseDocumentUnion[]
	>([]);

	const [title, setTitle] = useState<LocalizedStringDto | undefined>(undefined);
	const [subtitle, setSubtitle] = useState<LocalizedStringDto | undefined>(undefined);
	const [image1440x680url, setImage1440x680url] = useState("");
	const [image720x540url, setImage720x540url] = useState("");

	const isMobile = useIsMobileContext()

	useEffect(() => {
		if (key === undefined || competition === undefined) {
			return;
		}
		getData(competition, key)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getData = (competition: string, key: string) => {
		getSeasonTrophiesText(competition, key).then((result) => {
			setTitle(result.title);
			setImage1440x680url(result.image1440x680url ?? "");
			setImage720x540url(result.image720x540url ?? "")
			setContent(result.content ?? []);
			setSubtitle(result.subtitle);
		})
	}

	return (
		<Stack>
			<Banner title={title} subtitle={subtitle}
			        imageUrl={(isMobile && image720x540url) ? image720x540url : image1440x680url}
			        desktopHeight={(isMobile && image720x540url) ? 1080 : 680}
			/>
			<ContentContainer content={content}/>
		</Stack>
	)
}
