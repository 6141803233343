import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Stack,
	TextField,
	Typography
} from "@mui/material";
import getBrowserFingerprint from "get-browser-fingerprint";
import {Person} from "models/Person";
import React, {useState} from "react";
import {useTranslation} from 'react-i18next';
import {submitVote} from "../../../firestore/specificGetters/VoteRequests";

interface VotePopupProps {
	open: boolean;
	onClose: () => void;
	selectedPerson: Person;
}

export function VotePopup({open, onClose, selectedPerson}: VotePopupProps) {
	const {t} = useTranslation();
	const [email, setEmail] = useState("");
	const [voterName, setVoterName] = useState("");
	const [error, setError] = useState<string | null>(null);
	const [isSubmitting, setIsSubmitting] = useState(false);

	// Basic email validation
	const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
	const isEmailValid = emailRegex.test(email);
	const isNameValid = voterName.trim().length > 0;

	const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setEmail(e.target.value);
		setError(null);
	};

	const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setVoterName(e.target.value);
		setError(null);
	};

	const resetForm = () => {
		setEmail("");
		setVoterName("");
		setError(null);
		setIsSubmitting(false);
	};

	const handleClose = () => {
		resetForm();
		onClose();
	};

	const handleSubmit = async () => {
		if (!isEmailValid) {
			setError(t('voting.popup.errors.invalidEmail'));
			return;
		}

		if (!isNameValid) {
			setError(t('voting.popup.errors.nameRequired'));
			return;
		}

		const fingerprint = await getBrowserFingerprint()
		setIsSubmitting(true);
		setError(null);

		const result = await submitVote({
			email,
			voterName,
			personKey: selectedPerson.key,
			votingRound: "player-of-march-2024",
			fingerprint: fingerprint
		});

		setIsSubmitting(false);

		if (result.success) {
			handleClose();
		} else {
			setError(result.error === "You have already voted in this round"
				? t('voting.popup.errors.alreadyVoted')
				: t('voting.popup.errors.generic'));
		}
	};

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			maxWidth="sm"
			fullWidth
			PaperProps={{
				sx: {
					borderRadius: 2,
					p: 2
				}
			}}
		>
			<DialogTitle>
				{t('voting.popup.title')}
			</DialogTitle>
			<DialogContent>
				<Box sx={{mb: 3}}>
					<Typography variant="h6" sx={{fontWeight: 'bold'}}>
						{selectedPerson.firstName} {selectedPerson.lastName}
					</Typography>
				</Box>

				<Stack spacing={2}>
					<TextField
						autoFocus
						fullWidth
						label={t('voting.popup.nameLabel')}
						variant="outlined"
						value={voterName}
						onChange={handleNameChange}
						error={error === t('voting.popup.errors.nameRequired')}
						helperText={error === t('voting.popup.errors.nameRequired') ? error : ""}
						disabled={isSubmitting}
						inputProps={{
							autoComplete: "name",
							name: "name",
							type: "text",
							form: "voting-form"
						}}
					/>

					<TextField
						fullWidth
						label={t('voting.popup.emailLabel')}
						variant="outlined"
						value={email}
						onChange={handleEmailChange}
						error={!!error && error !== t('voting.popup.errors.nameRequired')}
						helperText={error !== t('voting.popup.errors.nameRequired') ? error : (email && !isEmailValid ? t('voting.popup.errors.invalidEmail') : "")}
						disabled={isSubmitting}
						inputProps={{
							autoComplete: "email",
							name: "email",
							type: "email",
							form: "voting-form"
						}}
					/>
				</Stack>

				<Typography variant="body2" color="text.secondary" sx={{mt: 2}}>
					{t('voting.popup.privacyNotice')}
				</Typography>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} disabled={isSubmitting}>
					{t('voting.popup.cancelButton')}
				</Button>
				<Button
					onClick={handleSubmit}
					variant="contained"
					disabled={!isEmailValid || !isNameValid || isSubmitting}
				>
					{isSubmitting ? t('voting.popup.submitting') : t('voting.popup.submitButton')}
				</Button>
			</DialogActions>

			<form id="voting-form" style={{display: 'none'}}/>
		</Dialog>
	);
} 