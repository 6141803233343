import {ButtonTabNavigatorButtons, useIsMobileContext} from "@nitra/nkmb-card-components";
import {InnerWidthContainer} from "components/widthControlElements";
import React, {useEffect} from "react";
import {useLocation} from "react-router-dom";
import {TeamNavigationBarDesktop} from "./TeamNavigationBarDesktop";

export function TeamNavigationBar() {
	const isMobile = useIsMobileContext();

	const {pathname} = useLocation()

	const [currentTab, setCurrentTab] = React.useState<"players" | "staff" | "playerOfTheMonth">("players")

	useEffect(() => {
		if (pathname.includes("players")) setCurrentTab("players")
		if (pathname.includes("staff")) setCurrentTab("staff")
		if (pathname.includes("playerOfTheMonth")) setCurrentTab("playerOfTheMonth")
	}, [pathname])

	return isMobile ?
		<InnerWidthContainer sx={{pt: 2}}>
			<ButtonTabNavigatorButtons
				tabs={[
					{
						label: {
							sl: "Igralski kader",
							en: "Players",
						},
						path: "players",
					},
					{
						label: {
							sl: "Strokovni štab",
							en: "Staff",
						}, path: "staff"
					},
					{
						label: {
							sl: "Igralec meseca",
							en: "Player of the month",
						},
						path: "playerOfTheMonth"
					},
				]}
			/>
		</InnerWidthContainer>
		:
		(<TeamNavigationBarDesktop currentTab={currentTab}/>)

}
