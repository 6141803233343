import {Stack} from "@mui/material";
import {ButtonTabNavigatorWithRouter, IButtonTabNavigatorTabWithComponent} from "@nitra/nkmb-card-components";
import {InnerWidthContainer} from "components/widthControlElements";
import {PlayerProfileBanner} from "views/team/playerDetailsPage/banner/PlayerProfileBanner";
import {
	CompetitionFilterButtonsContextProvider
} from "../../../components/CompetitionFilterButtons/CompetitionFilterButtonsContext";
import {PlayerDetailsContextProvider} from "./PlayerDetailsContext";
import {PlayerProfileTab} from "./PlayerProfileTab";
import {PlayerStatsContextProvider} from "./PlayerStatsContext";
import {PlayerStatTab} from "./PlayerStatTab";

const tabs: IButtonTabNavigatorTabWithComponent[] = [
	{
		label: {
			en: "Profile",
			sl: "Profil",
		},
		path: "profile",
		component: <PlayerProfileTab/>
	},
	{
		label: {
			en: "Statistics",
			sl: "Statistika",
		},
		path: "statistics",
		component: <PlayerStatTab/>
	}
]

export default function PlayerDetailsPage() {
	return (
		<PlayerDetailsContextProvider>
			<CompetitionFilterButtonsContextProvider allowMultipleSelection={false}>
				<PlayerStatsContextProvider>
					<Stack>
						{/*<PlayerProfilePlayerSelectDropdown/>*/}
						<PlayerProfileBanner/>
						<InnerWidthContainer>
							<ButtonTabNavigatorWithRouter tabs={tabs} blackVersion={true} sx={{pt: 2}}/>
						</InnerWidthContainer>
					</Stack>
				</PlayerStatsContextProvider>
			</CompetitionFilterButtonsContextProvider>
		</PlayerDetailsContextProvider>
	)
}
