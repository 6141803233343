import {collection, doc, getDoc} from "firebase/firestore";
import {db} from "firestore/BaseFirestore";
import {mapAndSortContent} from "firestore/mappers/ContentMapper";
import {
	StadiumLegendaryMatchFullFirestoreDocument,
	StadiumLegendaryMatchListContainerFirestoreDocument
} from "models/firestore/pageCache";

export async function GetStadiumLegendaryMatches(): Promise<StadiumLegendaryMatchListContainerFirestoreDocument> {
	const snapshot = await getDoc(doc(collection(db, 'pageCache'), 'stadium_legendary_matches'));

	if (!snapshot.exists()) throw new Error('Stadium page not found');
	// check if this is using the old non localized version and convert it to the new one by using the value for both en and sl
	const data = snapshot.data();

	return {
		matches:
			data.matches.map((match: any) => {
				if (match.title && typeof match.title === 'string') {
					const title = match.title;
					match.title = {
						en: title,
						sl: title
					}
				}

				if (match.subTitle && typeof match.subTitle === 'string') {
					const subTitle = match.subTitle;
					match.subTitle = {
						en: subTitle,
						sl: subTitle
					}
				}

				return {
					...match,
					date: match.date.toDate(),
				}
			})
	}
}

export async function getStadiumLegendaryMatch(key: string): Promise<StadiumLegendaryMatchFullFirestoreDocument> {
	const snapshot = await getDoc(doc(collection(db, 'pageCache/stadium_legendary_matches/stadiumLegendaryMatches'), key));

	if (!snapshot.exists()) throw new Error('Stadium match not found');

	// check if this is using the old non localized version and convert it to the new one by using the value for both en and sl
	if (snapshot.data().title && typeof snapshot.data().title === 'string') {
		const title = snapshot.data().title;
		snapshot.data().title = {
			en: title,
			sl: title
		}
	}

	return {
		...snapshot.data(),
		date: snapshot.data().date.toDate(),
		content: mapAndSortContent(snapshot.data().content),
	} as StadiumLegendaryMatchFullFirestoreDocument;
}
