import {Box, Grid, Stack, Typography, useTheme} from "@mui/material";
import useIosContext from "@nitra/nkmb-card-components/contexts/IosContextProvider";
import React from "react";
import {useTranslation} from "react-i18next";
import {SponsorCategoryFirestoreListDocument, SponsorFirestoreListDocument} from "../../../models";

export function MoreSponsorsMobile(props: {
	categories: SponsorCategoryFirestoreListDocument[],
	sponsors: SponsorFirestoreListDocument[]
}) {
	const {categories, sponsors} = props;
	const {t} = useTranslation();
	const {isIos} = useIosContext()
	const theme = useTheme();

	function getCategoryTranslation(level: number): string {
		switch (level) {
			case 1:
				return t('more.sponsors.mainSponsor');
			case 2:
				return t('more.sponsors.premiumPartners');
			case 3:
				return t('more.sponsors.officialPartners');
			default:
				return t('more.sponsors.partners');
		}
	}

	function getPercentWidth(level: number) {
		switch (level) {
			case 1:
				return "100%";
			case 2:
				return "50%";
			default:
				return "33.33%";
		}
	}

	function getPixelWidth(level: number) {
		switch (level) {
			case 1:
				return "200px";
			case 2:
				return "150px";
			default:
				return "100px";
		}
	}

	return (
		<Stack>
			{categories.sort((a, b) => a.level - b.level).map(category =>
				<Box
					sx={{
						pt: 5
					}}
					key={category.level}>
					<Typography variant={"h5"}
					            sx={{
						            [theme.breakpoints.down("sm")]: isIos && {
							            '@media (-webkit-min-device-pixel-ratio: 2)': {
								            fontWeight: 500, // Adjust font weight for iPhones
							            },
						            },
					            }}>{getCategoryTranslation(category.level)}</Typography>
					<Grid container spacing={3} sx={{
						pt: 4,
						width: "100vw",
						justifyContent: "space-evenly",
						"::after": category.level === 1 ? {} : {
							content: '""',
							width: getPercentWidth(category.level),
							minWidth: getPixelWidth(category.level),
							maxWidth: "200px",
						}
					}}>
						{
							sponsors
								.filter(sponsor => sponsor.level === category.level)
								.sort((a, b) => a.positionIndex - b.positionIndex)
								.map(sponsor =>
									<Grid item key={sponsor.key}
									      onClick={() => window.open(sponsor.url, "_blank")}
									      sx={{
										      width: getPercentWidth(category.level),
										      pb: `min(calc(${getPercentWidth(category.level)} - 16px), ${getPixelWidth(category.level)})`,
										      minWidth: getPixelWidth(category.level),
										      maxWidth: "200px",
										      backgroundImage: `url(${sponsor.image200x200url})`,
										      backgroundSize: "contain",
										      backgroundRepeat: "no-repeat"
									      }}
									/>
								)
						}
					</Grid>
				</Box>
			)}
		</Stack>
	)
}