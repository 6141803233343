import {Stack, Typography} from "@mui/material";
import {useIsMobileContext} from "@nitra/nkmb-card-components";
import React, {PropsWithChildren} from "react";
import {useTranslation} from "react-i18next";

export function MoreAbout() {
	const {t} = useTranslation();
	return (
		<Stack>
			<MoreAboutGroup title={t("more.about.president")}>
				<MoreAboutPerson name={"Drago Cotar"}/>
			</MoreAboutGroup>
			<MoreAboutGroup title={t("more.about.board")}>
				<MoreAboutSubGroup title={t("more.about.boardPresident")}>
					<MoreAboutPerson name={"Drago Cotar"}/>
				</MoreAboutSubGroup>
				<MoreAboutSubGroup title={t("more.about.vicePresidents")}>
					<MoreAboutPerson name={"Matjaž Kirbiš"}/>
					<MoreAboutPerson name={"Uroš Mlakar"}/>
				</MoreAboutSubGroup>
				<MoreAboutSubGroup title={t("more.about.members")}>
					<MoreAboutPerson name={"Rok Moljk"}/>
					<MoreAboutPerson name={"Jure Struc"}/>
					<MoreAboutPerson name={"Jure Bračko"}/>
					<MoreAboutPerson name={"Miha Pitamic"}/>
					<MoreAboutPerson name={"Valentino Mendek"}/>
					<MoreAboutPerson name={"Andrej Plos"}/>
				</MoreAboutSubGroup>
			</MoreAboutGroup>
			<MoreAboutGroup title={t("more.about.disciplinaryCommittee")}>
				<MoreAboutPerson name={"Jože Kreševič"}/>
				<MoreAboutPerson name={"Darko Vran"}/>
				<MoreAboutPerson name={"Milan Razdevšek"}/>
			</MoreAboutGroup>
			<MoreAboutGroup title={t("more.about.headOfFootballOperations")}>
				<MoreAboutPerson name={"Cem Başgül"} email={"cem.basgul@nkmaribor.com"}/>
			</MoreAboutGroup>
			<MoreAboutGroup title={t("more.about.sportsDirector")}>
				<MoreAboutPerson name={"Mihael Mikić"} email={"mihael.mikic@nkmaribor.com"}/>
			</MoreAboutGroup>
			<MoreAboutGroup title={t("more.about.assistantSportsDirector")}>
				<MoreAboutPerson name={"Marcos Tavares"}/>
			</MoreAboutGroup>
			<MoreAboutGroup title={t("more.about.director")}>
				<MoreAboutPerson name={"Bojan Ban"} email={"bojan.ban@nkmaribor.com"}/>
			</MoreAboutGroup>
			<MoreAboutGroup title={t("more.about.secretary")}>
				<MoreAboutPerson name={"Uroš Jurišič"} email={"uros.jurisic@nkmaribor.com"}/>
			</MoreAboutGroup>
			<MoreAboutGroup title={t("more.about.assistantSecretary")}>
				<MoreAboutPerson name={"Tadej Zajmi"} email={"tadej.zajmi@nkmaribor.com"}/>
			</MoreAboutGroup>
			<MoreAboutGroup title={t("more.about.secretariat")}>
				<MoreAboutPerson name={"Mojca Kramberger"}/>
				<MoreAboutPerson name={"Albina Dovnik"} email={"tajnistvo@nkmaribor.com"}/>
			</MoreAboutGroup>
			<MoreAboutGroup title={t("more.about.tickets")}>
				<MoreAboutPerson name={"Sandra Poljanec"} email={"sandra@nkmaribor.com"}/>
				<MoreAboutPerson name={"Marko Peroš"} email={"marko.peros@nkmaribor.com"}/>
			</MoreAboutGroup>
			<MoreAboutGroup title={t("more.about.prAndMedia")}>
				<MoreAboutPerson name={"Željko Latin"} email={"zeljko.latin@nkmaribor.com"}/>
				<MoreAboutPerson name={"Stipe Jerić"}/>
				<MoreAboutPerson name={"Miha Vidrih"}/>
				<MoreAboutPerson name={"Marijo Tomić"}/>
				<MoreAboutPerson name={"Grega Wernig"}/>
			</MoreAboutGroup>
			<MoreAboutGroup title={t("more.about.hospitalityManager")}>
				<MoreAboutPerson name={"Nataša Cvijanović"} email={"hospitality@nkmaribor.com"}/>
			</MoreAboutGroup>
			<MoreAboutGroup title={t("more.about.accounting")}>
				<MoreAboutPerson name={"Mateja Golnar Pesrl"} email={"racunovodstvo@nkmaribor.com"}/>
				<MoreAboutPerson name={"Cvetka Godec"}/>
				<MoreAboutPerson name={"Marija Kamenečki"}/>
				<MoreAboutPerson name={"Mateja Kamenečki"}/>
			</MoreAboutGroup>
		</Stack>
	)
}


export function MoreAboutGroup(props: PropsWithChildren<{
	title: string
}>) {
	const {title} = props;
	const isMobile = useIsMobileContext();

	return (
		<Stack sx={{pt: 6}}>
			<Typography variant={isMobile ? "podnaslovS" : "podnaslovL"}>{title}</Typography>
			{props.children}
		</Stack>
	)
}

export function MoreAboutSubGroup(props: PropsWithChildren<{
	title: string
}>) {
	const {title} = props;
	const isMobile = useIsMobileContext();

	return (
		<Stack sx={{pt: 2}}>
			<Typography variant={isMobile ? "bodyMBold" : "podnaslovS"}>{title}</Typography>
			{props.children}
		</Stack>
	)
}

export function MoreAboutPerson(props: {
	name: string,
	email?: string,
}) {
	const {name, email} = props;

	return (
		<Stack>
			<Typography variant={"bodyXL"}>{name}</Typography>
			{email && <Typography variant={"bodyM"} sx={{color: "#676767", mt: "-6px"}}>{email}</Typography>}
		</Stack>
	)
}
