import {Stack} from "@mui/material";
import {INavigationBarTab} from "components/NavigationBar/INavigationBarTab";
import {NavigationBar} from "components/NavigationBar/NavigationBar";
import {InnerWidthContainer} from "components/widthControlElements";
import React from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import {MoreAbout} from "views/more/MoreAbout";
import {MoreContact} from "views/more/MoreContact";
import {MoreSponsors} from "views/more/MoreSponsors";
import {MoreCookies} from "./MoreCookies";

const tabs: INavigationBarTab[] = [
	{label: "appBar.navigation.more.contact", url: "contact"},
	{label: "appBar.navigation.more.sponsors", url: "sponsors"},
	{label: "appBar.navigation.more.about", url: "about"},
]

export function MoreRouter() {
	return (
		<Stack>
			<NavigationBar tabs={tabs}/>
			<InnerWidthContainer>
				<Routes>
					<Route path={"/"} element={<Navigate replace to={"contact"}/>}/>
					<Route path="contact" element={<MoreContact/>}/>
					<Route path="sponsors" element={<MoreSponsors/>}/>
					<Route path="about" element={<MoreAbout/>}/>
					<Route path={"/cookies"} element={<MoreCookies/>}/>
				</Routes>
			</InnerWidthContainer>
		</Stack>
	)
}

