import {doc, getDoc, serverTimestamp, setDoc} from "firebase/firestore";
import {Vote, VoteSubmission} from "../../models/Vote";
import {db} from "../BaseFirestore";

const VOTES_COLLECTION = 'votes';

export async function submitVote(voteSubmission: VoteSubmission): Promise<{ success: boolean, error?: string }> {
	// Basic email validation
	const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
	if (!emailRegex.test(voteSubmission.email)) {
		return {
			success: false,
			error: "Invalid email format"
		};
	}

	try {
		const docRef = doc(db, `${VOTES_COLLECTION}/march2024/votes`, voteSubmission.email);

		// Check if this email has already voted in this round
		const existingVote = await getDoc(docRef);
		if (existingVote.exists()) {
			return {
				success: false,
				error: "You have already voted in this round"
			};
		}

		// Create the vote document with server timestamp
		const vote: Vote = {
			...voteSubmission,
			timestamp: serverTimestamp() as any
		};

		// Add the vote to Firestore with specific document ID
		await setDoc(docRef, vote);

		return {
			success: true
		};
	} catch (error) {
		console.error("Error submitting vote:", error);
		return {
			success: false,
			error: "An error occurred while submitting your vote"
		};
	}
}
