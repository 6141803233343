import {ArticleListFirestoreDocument} from "@nitra/nkmb-card-components";

export function documentSnapshotToArticleListFirestoreDocument(payload: any): ArticleListFirestoreDocument {
	// check if this is using the old non localized version and convert it to the new one by using the value for both en and sl
	if (payload.title && typeof payload.title === "string") {
		payload.title = {
			en: payload.title,
			sl: payload.title
		}
	}

	if (payload.shortText && typeof payload.shortText === "string") {
		payload.shortText = {
			en: payload.shortText,
			sl: payload.shortText
		}
	}

	const createdAt: Date = payload.createdAt && typeof payload.createdAt === "string" ? new Date(payload.createdAt) : payload.createdAt.toDate();
	const updatedAt: Date = payload.updatedAt && typeof payload.updatedAt === "string" ? new Date(payload.updatedAt) : payload.updatedAt.toDate();

	return {
		...payload,
		createdAt,
		updatedAt
	}
}