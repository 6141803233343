import {ArticleListFirestoreDocument} from "@nitra/nkmb-card-components";
import {collection, doc, getDoc} from "firebase/firestore";
import {db, IAdvancedNewsParameters} from "firestore/BaseFirestore";
import {
	FootballSchoolSelectionFullFirestoreDocument,
	FootballSchoolStaffListFirestoreDocument
} from "models/firestore/pageCache/footballSchool";
import PageDto from "../../models/common/PagableDto";
import ArticlePageCacheFirebaseDocument
	from "../../models/firestore/pageCache/article/ArticlePageCacheFirebaseDocument";
import {functionConfig} from "../firebaseConfig";
import {mapAndSortContent} from "../mappers/ContentMapper";
import {
	documentSnapshotToArticleListFirestoreDocument
} from "../mappers/DocumentSnapshotToArticleListFirestoreDocument";

export async function getSelection(selection: string): Promise<FootballSchoolSelectionFullFirestoreDocument> {
	const snapshot = await getDoc(doc(collection(db, '/pageCache/footballSchoolSelection/footballSchoolSelection'), selection.toUpperCase()));

	if (!snapshot.exists()) throw new Error('Football school selection not found');

	return {
		...snapshot.data(),
	} as FootballSchoolSelectionFullFirestoreDocument;
}

export async function getFootballSchoolNewsFeed(params: IAdvancedNewsParameters = {}) {
	const {pageSize, month, year, searchQuery, lastId, page} = params;

	if (lastId === undefined && month === undefined && year === undefined && searchQuery === undefined) { // get cached news from the database
		const newsSnapshot = await getDoc(doc(collection(db, 'footballSchoolArticleCache'), 'newsFeed')); // TODO this name for firestore collection is hardcoded

		if (!newsSnapshot.exists()) throw new Error('News page not found');

		return {
			...newsSnapshot.data(),
			number: 0,
			totalPages: 50,
			totalElements: 5000,
			content: newsSnapshot.data().articles.map((newsSnapshot: any) => documentSnapshotToArticleListFirestoreDocument(newsSnapshot)),
		} as PageDto<ArticleListFirestoreDocument>;
	} else { // get news from the function to allow scrolling further into the past
		let url = `${functionConfig.url}footballSchoolNewsSearch?`;
		if (lastId) url += `lastFirestoreId=${lastId}`;
		if (pageSize) url += `&pageSize=${pageSize}`;
		if (month) url += `&month=${month}`;
		if (year) url += `&year=${year}`;
		if (searchQuery) url += `&searchQuery=${searchQuery}`;
		if (page) url += `&page=${page}`

		return fetch(url)
			.then(response => response.json())
			.then(data => {
				return {
					...data,
					content: data.results.map((item: any) => documentSnapshotToArticleListFirestoreDocument(item)),
				} as PageDto<ArticleListFirestoreDocument>
			})

	}
}

export async function getFootballSchoolStaff(): Promise<FootballSchoolStaffListFirestoreDocument[]> {
	const snapshot = await getDoc(doc(collection(db, '/pageCache'), 'footballSchoolStaff'));

	if (!snapshot.exists()) throw new Error('Football school staff not found');

	return snapshot.data().staff.map((item: any) => ({
		...item,
	} as FootballSchoolStaffListFirestoreDocument));
}

export async function getFootballSchoolNewsArticle(key: string): Promise<ArticlePageCacheFirebaseDocument> {
	const snapshot = await getDoc(doc(collection(db, '/articleCache'), key));

	if (!snapshot.exists()) throw new Error('Football school article not found');

	// check if this is using the old non localized version and convert it to the new one by using the value for both en and sl
	if (snapshot.data().title && typeof snapshot.data().title === 'string') {
		snapshot.data().title = {
			en: snapshot.data().title,
			sl: snapshot.data().title,
		}
	}

	if (snapshot.data().shortText && typeof snapshot.data().shortText === 'string') {
		snapshot.data().shortText = {
			en: snapshot.data().shortText,
			sl: snapshot.data().shortText,
		}
	}

	return {
		...snapshot.data(),
		createdAt: snapshot.data().createdAt.toDate(),
		updatedAt: snapshot.data().updatedAt.toDate(),
		content: mapAndSortContent(snapshot.data().content),
		suggestedArticles: snapshot.data().suggestedArticles && snapshot.data().suggestedArticles.map((article: any) => documentSnapshotToArticleListFirestoreDocument(article)),
		nextArticle: snapshot.data().nextArticle && documentSnapshotToArticleListFirestoreDocument(snapshot.data().nextArticle),
	} as ArticlePageCacheFirebaseDocument;
}