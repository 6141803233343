import {ButtonTabNavigatorWithRouter, useSeasonSettings} from "@nitra/nkmb-card-components";
import React, {useEffect} from "react";
import {DesktopClubStatistics} from "views/club/statistics/desktop/club/DesktopClubStatistics";
import {DesktopPlayerStatistics} from "views/club/statistics/desktop/player/DesktopPlayerStatistics";

const tabs = [{
	path: "standings", label: {en: "Club", sl: "Klub"}, component: <DesktopClubStatistics/>
}, {
	path: "players", label: {en: "Players", sl: "Igralci"}, component: <DesktopPlayerStatistics/>
}]

export function DesktopStatistics() {
	const {setAvailableSeasonsOverride, setFirstSeasonOverride, setLastSeasonOverride} = useSeasonSettings()

	useEffect(() => {
		setAvailableSeasonsOverride(undefined)
		setFirstSeasonOverride(undefined)
		setLastSeasonOverride(undefined)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<ButtonTabNavigatorWithRouter tabs={tabs} sx={{px: 0, pt: 2}} blackVersion={true}/>
	)
}
