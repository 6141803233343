import {Box} from "@mui/material";
import {ButtonTabNavigatorButtons, IButtonTabNavigatorTab} from "@nitra/nkmb-card-components";
import React from "react";

const tabs: IButtonTabNavigatorTab[] = [
	{label: {en: "Info", sl: "Info"}, path: "info"},
	{label: {en: "Stadium History", sl: "Zgodovina"}, path: "stadiumHistory"},
	{label: {en: "Legendary Matches", sl: "Legendarne tekme"}, path: "legendaryMatches"},
]

export function ClubStadiumTabNavigatorButtons() {
	return (
		<Box sx={{px: 2}}>
			<ButtonTabNavigatorButtons
				tabs={tabs}
				replaceLastPath={true}
			/>
		</Box>
	)
}
