import {Stack, Typography} from "@mui/material";
import {useIsMobileContext} from "@nitra/nkmb-card-components";
import Banner from "components/Banner";
import {getFirstTeam} from "firestore/BaseFirestore";
import {Group} from "models/Group";
import {Person} from "models/Person";
import React, {useEffect, useState} from "react";
import {VotePopup} from "./peopleGrid/VotePopup";
import {VotingGroupElement} from "./peopleGrid/VotingGroupElement";

export function VotingContainer() {
	const [selectedPerson, setSelectedPerson] = useState<Person | null>(null);
	const [isVotePopupOpen, setIsVotePopupOpen] = useState(false);

	const [groups, setGroups] = React.useState<Group<Person>[]>([])
	const isMobile = useIsMobileContext();

	const votableNames = [
		"SOUDANI",
		"TETTEH",
		"GRLIĆ",
		"OJO",
		"ŠIRVYS",
	]

	useEffect(() => {
		getFirstTeam().then(
			(data) => {

				const joinedData = [...data.goalkeepers.sort((a, b) => (a.jerseyNumber ?? 0) - (b.jerseyNumber ?? 0)), ...data.defenders.sort((a, b) => (a.jerseyNumber ?? 0) - (b.jerseyNumber ?? 0)), ...data.midfielders.sort((a, b) => (a.jerseyNumber ?? 0) - (b.jerseyNumber ?? 0)), ...data.forwards.sort((a, b) => (a.jerseyNumber ?? 0) - (b.jerseyNumber ?? 0))]
				const filteredData = joinedData
					.filter(person => votableNames.indexOf(person.lastName.toUpperCase()) !== -1)
					.sort((a, b) => (a.jerseyNumber ?? 0) - (b.jerseyNumber ?? 0))

				setGroups([
					{
						name: "IZBIRAMO IGRALCA MARCA",
						items: filteredData
					},
				])
			})
	}, [])

	const handlePersonSelect = (person: Person) => {
		setSelectedPerson(person);
		setIsVotePopupOpen(true);
	};

	return (
		<Stack>
			<Banner
				imageUrl={isMobile ? "/images/team/banners/igralec_meseca_banner_mobile.png" : "/images/team/banners/igralec_meseca_banner_desktop.png"}
				desktopHeight={isMobile ? 1080 : 480}
				sx={{mt: isMobile ? 3 : 0}}
			/>
			<Stack sx={{px: 2}}>
				<Stack spacing={5} sx={{pt: 5}}>

					<Typography variant={"bodyXL"}>
						Končan je tekmovalni program v marcu: odigrali smo pet tekem, zabeležili štiri zmage in en
						poraz. Dosegli dvanajst, prejeli štiri zadetke.
					</Typography>
					<Typography variant={"bodyXL"}>
						Čas je za oceno storjenega, skupaj bomo izbrali najboljšega posameznika v vijoličastem v marcu.
						Kandidati so: Hilal Soudani, Benjamin Tetteh, Niko Grlić, Sheyi Ojo in Pijus Širvys.
					</Typography>
					<Typography variant={"bodyXL"}>
						Med vsemi, ki boste glasovali za zmagovalca izbora za Naj igralca marca, bomo izžrebali
						nagrajenca, ki bo prejel 2 vstopnici za tekmo.
					</Typography>
					<Typography variant={"bodyXL"}>
						Glasovanje bo potekalo do četrtka, 3. aprila. Do 12. ure.
					</Typography>
				</Stack>

				<Stack spacing={2}>
					{groups.map((group, index) => (
						<VotingGroupElement
							key={index}
							group={group}
							onPersonSelect={handlePersonSelect}
						/>
					))}
					{selectedPerson && (
						<VotePopup
							open={isVotePopupOpen}
							onClose={() => setIsVotePopupOpen(false)}
							selectedPerson={selectedPerson}
						/>
					)}
				</Stack>
			</Stack>
		</Stack>
	);
} 