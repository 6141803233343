import {ButtonTabNavigatorWithRouter} from "@nitra/nkmb-card-components";
import React from "react";
import {CompetitionStatisticsContextProvider} from "../common/CompetitionStatisticsContext";
import {CompetitionClubStatistics} from "./CompetitionClubStatistics";
import {CompetitionPlayerStatistics} from "./CompetitionPlayerStatistics";

const tabs = [{
	path: "club", label: {
		"en": "Club",
		"sl": "Klub"
	}, component: <CompetitionClubStatistics/>
}, {
	path: "players", label: {
		"en": "Players",
		"sl": "Igralci"
	}, component: <CompetitionPlayerStatistics/>
}]

export function CompetitionStatistics() {
	return (
		<CompetitionStatisticsContextProvider>
			<ButtonTabNavigatorWithRouter tabs={tabs} sx={{px: 0, pt: 2}} blackVersion={true}/>
		</CompetitionStatisticsContextProvider>
	)
}