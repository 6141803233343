export interface INavigationTarget {
	title: string;
	url: string;
	children?: INavigationTarget[];
	showInMobile: boolean;
	showInDesktop: boolean;
}

export const navigationTargets: INavigationTarget[] = [
	{
		title: "appBar.navigation.home",
		url: "/",
		showInMobile: true,
		showInDesktop: false,
	},
	{
		title: "appBar.navigation.news.title",
		url: "/news/feed",
		children: [
			{
				title: "appBar.navigation.news.articles", url: "/news/feed",
				showInMobile: true,
				showInDesktop: true
			},
			{
				title: "appBar.navigation.news.interviews", url: "/news",
				showInMobile: false,
				showInDesktop: false
			},
			{
				title: "appBar.navigation.news.matchReports", url: "/news",
				showInMobile: false,
				showInDesktop: false
			},
			{
				title: "appBar.navigation.news.video", url: "/news/video",
				showInMobile: true,
				showInDesktop: true
			},
		],
		showInMobile: true,
		showInDesktop: true,
	},
	{
		title: "appBar.navigation.tickets.title",
		url: "/tickets",
		// children: [
		// 	{
		// 		title: "Dnevne vstopnice", url: "/tickets",
		// 		showInMobile: true,
		// 		showInDesktop: false
		// 	},
		// 	{
		// 		title: "Sezonske vstopnice", url: "/tickets",
		// 		showInMobile: true,
		// 		showInDesktop: false
		// 	},
		//
		// ],
		showInMobile: true,
		showInDesktop: true,
	},
	{
		title: "appBar.navigation.competitions.title",
		url: "/competitions/matches",
		children: [
			{
				title: "appBar.navigation.competitions.matches", url: "/competitions/matches",
				showInMobile: true,
				showInDesktop: false
			},
			{
				title: "appBar.navigation.competitions.standings", url: "/competitions/ledger",
				showInMobile: true,
				showInDesktop: false
			},
			{
				title: "appBar.navigation.competitions.calendar", url: "/competitions/calendar",
				showInMobile: true,
				showInDesktop: false
			},
			{
				title: "appBar.navigation.competitions.statistics", url: "/competitions/statistics",
				showInMobile: true,
				showInDesktop: false
			},
		],
		showInMobile: true,
		showInDesktop: true,
	},
	{
		title: "appBar.navigation.hospitality.title",
		url: "/hospitality",
		children: [
			{
				title: "appBar.navigation.hospitality.vps", url: "/hospitality/vps",
				showInMobile: true,
				showInDesktop: false
			},
			{
				title: "appBar.navigation.hospitality.skybox", url: "/hospitality/skybox",
				showInMobile: true,
				showInDesktop: false
			},
		],
		showInMobile: true,
		showInDesktop: true,
	},
	{
		title: "appBar.navigation.team.title",
		url: "/team/players",
		children: [
			{
				title: "appBar.navigation.team.firstTeam", url: "/team/players",
				showInMobile: true,
				showInDesktop: false
			},
			{
				title: "appBar.navigation.team.staff", url: "/team/staff",
				showInMobile: true,
				showInDesktop: false
			},
		],
		showInMobile: true,
		showInDesktop: true,
	},
	{
		title: "appBar.navigation.club.title",
		url: "/club/history/legends",
		children: [
			{
				title: "appBar.navigation.club.history", url: "/club/history/legends",
				showInMobile: true,
				showInDesktop: false
			},
			{
				title: "appBar.navigation.club.trophies", url: "/club/trophies",
				showInMobile: true,
				showInDesktop: false
			},
			{
				title: "appBar.navigation.club.stadium", url: "/club/stadium",
				showInMobile: true,
				showInDesktop: false
			},
			{
				title: "appBar.navigation.club.fans", url: "/club/fans",
				showInMobile: true,
				showInDesktop: false
			},
			{
				title: "appBar.navigation.club.symbols", url: "/club/symbols",
				showInMobile: true,
				showInDesktop: false
			},
			{
				title: "appBar.navigation.club.statistics", url: "/club/statistics",
				showInMobile: true,
				showInDesktop: false
			},
		],
		showInMobile: true,
		showInDesktop: true,
	},
	{
		title: "appBar.navigation.footballSchool",
		url: "/footballSchool",
		showInMobile: true,
		showInDesktop: true,
	},
	{
		title: "appBar.navigation.more.title",
		url: "/more",
		children: [
			{
				title: "appBar.navigation.more.contact", url: "/more/contact",
				showInMobile: true,
				showInDesktop: false
			},
			{
				title: "appBar.navigation.more.sponsors", url: "/more/sponsors",
				showInMobile: true,
				showInDesktop: false
			},
			{
				title: "appBar.navigation.more.about", url: "/more/about",
				showInMobile: true,
				showInDesktop: false
			},
		],
		showInMobile: true,
		showInDesktop: true,
	},
]
