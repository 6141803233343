import {Stack, Typography, useTheme} from "@mui/material";
import {getLocalizedString, LocalizedStringDto} from "@nitra/nkmb-card-components";
import useIosContext from "@nitra/nkmb-card-components/contexts/IosContextProvider";
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate} from "react-router-dom";


export function MobileNextArticle(props: {
	data: {
		title: LocalizedStringDto,
		firestoreId: string
		image600x360url: string,
	}
}) {
	const {title, firestoreId, image600x360url} = props.data;
	const navigate = useNavigate()
	const location = useLocation()

	const {isIos} = useIosContext()
	const theme = useTheme();
	const {i18n} = useTranslation();
	const currentLang = i18n.language;

	return (
		<Stack spacing={1}
		       onClick={() => navigate((location.pathname.indexOf("footballSchool") !== -1) ? `/footballSchool/news/${firestoreId}` : `../${firestoreId}`)}
		       sx={{p: 2, mx: "-16px"}}>
			<Typography variant={"gumbL"}>Naslednji članek</Typography>
			<Typography variant={"h4"} sx={{
				[theme.breakpoints.down("sm")]: isIos && {
					'@media (-webkit-min-device-pixel-ratio: 2)': {
						fontWeight: 500, // Adjust font weight for iPhones
					},
				},
			}}>{getLocalizedString(title, currentLang)}</Typography>
			<div
				style={{
					width: "100%",
					height: 199,
					borderRadius: "8px",
					backgroundImage: `url(${image600x360url})`,
					backgroundSize: 'cover',
					backgroundPosition: 'center',
					backgroundRepeat: 'no-repeat'

				}}
			/>
		</Stack>
	)
}