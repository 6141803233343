import {
	ArticleListFirestoreDocument,
	MatchCardDto,
	SeasonMatchesContainerFirestoreDocument
} from "@nitra/nkmb-card-components";
import {initializeApp} from "firebase/app";
import {collection, doc, getDoc, getFirestore} from "firebase/firestore";
import PageDto from "models/common/PagableDto";
import {EClubInfoPage} from "models/enums/EClubInfoPage";
import {
	LegendWarriorDecadeListContainerFirestoreDocument
} from "models/firestore/containers/LegendWarriorDecadeListContainerFirestoreDocument";
import {
	ArticleMatchMetaDataFirestoreDocument,
	ClubInfoPageFirestoreDocument,
	DecadeFullFirestoreDocument,
	LegendFullFirestoreDocument,
	PlayerProfileFirebaseDocument,
	PlayerStatsFirebaseDocument,
	WarriorFullFirestoreDocument
} from "models/firestore/pageCache";
import {Person} from "models/Person";
import StandingsContainerFirestoreDocument from "../models/firestore/containers/StandingsContainerFirestoreDocument";
import ArticlePageCacheFirebaseDocument from "../models/firestore/pageCache/article/ArticlePageCacheFirebaseDocument";
import LandingPageCacheFirestoreDocument from "../models/firestore/pageCache/LandingPageCacheFirestoreDocument";
import {firebaseConfig, functionConfig} from "./firebaseConfig";
import {mapAndSortContent} from "./mappers/ContentMapper";
import {documentSnapshotToArticleListFirestoreDocument} from "./mappers/DocumentSnapshotToArticleListFirestoreDocument";
import {documentSnapshotToBannerFirestoreDocument} from "./mappers/DocumentSnapshotToBannerFirestoreDocument";
import {documentSnapshotToNewsArticleCardDtoMapper} from "./mappers/DocumentSnapshotToNewsArticleCardDtoMapper";
import {playerProfileMapper, playerProfileStatsMapper} from "./mappers/PlayerProfileMapper";


// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);

export async function getLanding(): Promise<LandingPageCacheFirestoreDocument> {
	const landingSnapshot = await getDoc(doc(collection(db, 'pageCache'), 'landing'));

	if (!landingSnapshot.exists()) throw new Error('Landing page not found');

	return {
		news: documentSnapshotToNewsArticleCardDtoMapper(landingSnapshot.data().news),
		matches: landingSnapshot.data().matches.map((match: any) => {
			return {
				...match,
				matchDate: match.matchDate.toDate(),
			} as MatchCardDto
		}),
		products: landingSnapshot.data().products.map((product: any) => {
			// check if this is using the old non localized version and convert it to the new one by using the value for both en and sl
			if (product.title && typeof product.title === 'string') {
				product.title = {
					en: product.title,
					sl: product.title,
				}
			}
			return product
		}),
		topBanner: documentSnapshotToBannerFirestoreDocument(landingSnapshot.data().topBanner),
		promotedMatchBanner: landingSnapshot.data().promotedMatchBanner ? {
			...landingSnapshot.data().promotedMatchBanner,
			date: landingSnapshot.data().promotedMatchBanner.date.toDate(),
		} : undefined,
		randomPlayer: landingSnapshot.data().randomPlayer,
		video: {
			...landingSnapshot.data().video,
			publishedAt: landingSnapshot.data().video.publishedAt.toDate(),
		},
	} as LandingPageCacheFirestoreDocument;
}

export interface IAdvancedNewsParameters {
	pageSize?: number,
	month?: number,
	year?: number,
	searchQuery?: string,
	lastId?: string,
	page?: number,
}

export async function getAdvancedNewsFeed(
	params: IAdvancedNewsParameters = {}
): Promise<PageDto<ArticleListFirestoreDocument>> {
	const {pageSize, month, year, searchQuery, lastId, page} = params;

	if (lastId === undefined && month === undefined && year === undefined && searchQuery === undefined) { // get cached news from the database
		const newsSnapshot = await getDoc(doc(collection(db, 'pageCache'), 'news'));

		if (!newsSnapshot.exists()) throw new Error('News page not found');

		return {
			...newsSnapshot.data(),
			number: 0,
			totalPages: 50,
			totalElements: 5000,
			content: newsSnapshot.data().news.map((newsSnapshot: any) => documentSnapshotToArticleListFirestoreDocument(newsSnapshot)),
		} as PageDto<ArticleListFirestoreDocument>;
	} else { // get news from the function to allow scrolling further into the past
		let url = `${functionConfig.url}newsSearch?`;
		if (lastId) url += `lastFirestoreId=${lastId}`;
		if (pageSize) url += `&pageSize=${pageSize}`;
		if (month) url += `&month=${month}`;
		if (year) url += `&year=${year}`;
		if (searchQuery) url += `&searchQuery=${searchQuery}`;
		if (page) url += `&page=${page}`


		return fetch(url)
			.then(response => response.json())
			.then(data => {
				return {
					...data,
					content: data.results.map((item: any) => documentSnapshotToArticleListFirestoreDocument(item)),
				} as PageDto<ArticleListFirestoreDocument>
			})

	}
}

export async function getNewsArticle(key: string): Promise<ArticlePageCacheFirebaseDocument> {
	const articleSnapshot = await getDoc(doc(collection(db, 'articleCache'), key));

	if (!articleSnapshot.exists()) throw new Error('Article not found');

	const matchMetaData: ArticleMatchMetaDataFirestoreDocument | undefined = articleSnapshot.data().matchMetaData

	// check if this is using the old non localized version and convert it to the new one by using the value for both en and sl
	if (articleSnapshot.data().title && typeof articleSnapshot.data().title === 'string') {
		articleSnapshot.data().title = {
			en: articleSnapshot.data().title,
			sl: articleSnapshot.data().title,
		}
	}

	if (articleSnapshot.data().shortText && typeof articleSnapshot.data().shortText === 'string') {
		articleSnapshot.data().shortText = {
			en: articleSnapshot.data().shortText,
			sl: articleSnapshot.data().shortText,
		}
	}

	return {
		...articleSnapshot.data(),
		createdAt: articleSnapshot.data().createdAt.toDate(),
		updatedAt: articleSnapshot.data().updatedAt.toDate(),
		content: mapAndSortContent(articleSnapshot.data().content),
		suggestedArticles: articleSnapshot.data().suggestedArticles && articleSnapshot.data().suggestedArticles.map((article: any) => documentSnapshotToArticleListFirestoreDocument(article)),
		nextArticle: articleSnapshot.data().nextArticle && documentSnapshotToArticleListFirestoreDocument(articleSnapshot.data().nextArticle),
		matchMetaData: !!matchMetaData && {
			...matchMetaData,
			homeTeamSubstitutions: matchMetaData.homeTeamSubstitutions ?? [],
			awayTeamSubstitutions: matchMetaData.awayTeamSubstitutions ?? [],
			homeTeamCards: matchMetaData.homeTeamCards ?? [],
			awayTeamCards: matchMetaData.awayTeamCards ?? [],
			homeTeamGoals: matchMetaData.homeTeamGoals ?? [],
			awayTeamGoals: matchMetaData.awayTeamGoals ?? [],
			homeTeamLineup: matchMetaData.homeTeamLineup ?? [],
			awayTeamLineup: matchMetaData.awayTeamLineup ?? [],
			// @ts-ignore
			date: matchMetaData.date.toDate(),
		} as ArticleMatchMetaDataFirestoreDocument,
	} as ArticlePageCacheFirebaseDocument;
}

export async function getCurrentStandings(season: string): Promise<StandingsContainerFirestoreDocument> {
	if (season === undefined || season === "") {
		return Promise.reject("Season request isn't valid. Season: " + season)
	}

	const standingsSnapshot = await getDoc(doc(collection(db, 'pageCache'), `standings_${season.replace("/", "")}_FIRST_LEAGUE`));

	if (!standingsSnapshot.exists()) throw new Error('Standings not found');

	return standingsSnapshot.data() as StandingsContainerFirestoreDocument;
}

/**
 *
 * @param season Season in the form of [year][year] (20222023)
 */
export async function getSeasonMatches(season: number | string): Promise<SeasonMatchesContainerFirestoreDocument> {
	if (typeof season === 'string') season = parseInt(season.replace('/', ''));

	if (season === 42 || isNaN(season)) {
		return Promise.reject("Season request isn't valid. Season: " + season)
	}

	const matchesSnapshot = await getDoc(doc(collection(db, 'pageCache'), `matches_${season}`));

	if (!matchesSnapshot.exists()) throw new Error('Matches not found');

	const cleanedMatches = matchesSnapshot.data().matches.map((match: any) => {
		return {
			...match,
			date: match.date.toDate(),
		}
	})

	return {
		season: matchesSnapshot.data().season,
		matches: cleanedMatches,
		scoreStats: matchesSnapshot.data().scoreStats,
	} as SeasonMatchesContainerFirestoreDocument;
}

export interface FirstTeamCacheFirestoreDocument {
	goalkeepers: Person[],
	defenders: Person[],
	midfielders: Person[],
	forwards: Person[],
}

export async function getFirstTeam(): Promise<FirstTeamCacheFirestoreDocument> {
	const firstTeamSnapshot = await getDoc(doc(collection(db, 'pageCache'), 'first_team'));

	if (!firstTeamSnapshot.exists()) throw new Error('First team not found');

	return {
		goalkeepers: firstTeamSnapshot.data().goalkeepers,
		defenders: firstTeamSnapshot.data().defenders,
		midfielders: firstTeamSnapshot.data().midfielders,
		forwards: firstTeamSnapshot.data().forwards,
	}
}

export async function getClubInfoPage(page: EClubInfoPage): Promise<ClubInfoPageFirestoreDocument> {
	const snapshot = await getDoc(doc(collection(db, 'pageCache'), `club_info_${page.toLowerCase()}`));

	if (!snapshot.exists()) throw new Error('Club info page not found');

	// check if this is using the old non localized version and convert it to the new one by using the value for both en and sl
	if (snapshot.data().bannerHeaderMainText && typeof snapshot.data().bannerHeaderMainText === 'string') {
		snapshot.data().bannerHeaderMainText = {
			en: snapshot.data().bannerHeaderMainText,
			sl: snapshot.data().bannerHeaderMainText,
		}
	}

	if (snapshot.data().bannerHeaderSubText && typeof snapshot.data().bannerHeaderSubText === 'string') {
		snapshot.data().bannerHeaderSubText = {
			en: snapshot.data().bannerHeaderSubText,
			sl: snapshot.data().bannerHeaderSubText,
		}
	}

	return {
		bannerHeaderMainText: snapshot.data().bannerHeaderMainText,
		bannerHeaderSubText: snapshot.data().bannerHeaderSubText,
		bannerHeaderImageUrl: snapshot.data().bannerHeaderImageUrl,
		content: mapAndSortContent(snapshot.data().content),
	} as ClubInfoPageFirestoreDocument;
}

export async function getPlayerProfile(playerKey: string): Promise<PlayerProfileFirebaseDocument> {
	const snapshot = await getDoc(doc(collection(db, 'pageCache/player_profile/pageCachePlayers'), playerKey));

	if (!snapshot.exists()) throw new Error('Player profile not found');

	return playerProfileMapper(snapshot.data());
}

export async function getPlayerStatsForProfile(playerKey: string): Promise<PlayerStatsFirebaseDocument> {
	const snapshot = await getDoc(doc(collection(db, 'pageCache/player_profile/playerProfileStats'), playerKey));

	if (!snapshot.exists()) throw new Error('Player profile not found');

	return playerProfileStatsMapper(snapshot.data());
}

export async function getClubLegendWarriorDecade(): Promise<LegendWarriorDecadeListContainerFirestoreDocument> {
	const snapshot = await getDoc(doc(collection(db, 'pageCache'), 'club_history'));

	if (!snapshot.exists()) throw new Error('Club history page not found');

	// check if this is using the old non localized version and convert it to the new one by using the value for both en and sl
	const data = snapshot.data();
	data.decades.map((decade: any) => {
		if (decade.title && typeof decade.title === 'string') {
			decade.title = {
				en: decade.title,
				sl: decade.title
			}
		}
		if (decade.subTitle && typeof decade.subTitle === 'string') {
			decade.subTitle = {
				en: decade.subTitle,
				sl: decade.subTitle
			}
		}
		return decade;
	})
	data.legends.map((legend: any) => {
		if (legend.cardSubText && typeof legend.cardSubText === 'string') {
			legend.cardSubText = {
				en: legend.cardSubText,
				sl: legend.cardSubText
			}
		}
		return legend;
	})

	return data as LegendWarriorDecadeListContainerFirestoreDocument;
}

export async function getLegend(key: string): Promise<LegendFullFirestoreDocument> {
	const snapshot = await getDoc(doc(collection(db, 'pageCache/legend/legends'), key));

	if (!snapshot.exists()) throw new Error('Legend not found');

	return {
		...snapshot.data(),
		content: mapAndSortContent(snapshot.data().content),
		dateOfBirth: snapshot.data().dateOfBirth ? snapshot.data().dateOfBirth.toDate() : undefined,
	} as LegendFullFirestoreDocument;
}

export async function getWarrior(key: string): Promise<WarriorFullFirestoreDocument> {
	const snapshot = await getDoc(doc(collection(db, 'pageCache/warrior/warriors'), key));

	if (!snapshot.exists()) throw new Error('Warrior not found');

	return {
		...snapshot.data(),
		content: mapAndSortContent(snapshot.data().content),
	} as WarriorFullFirestoreDocument;
}

export async function getDecade(key: string): Promise<DecadeFullFirestoreDocument> {
	const snapshot = await getDoc(doc(collection(db, 'pageCache/decade/decades'), key));

	if (!snapshot.exists()) throw new Error('Decade not found');

	return {
		...snapshot.data(),
		content: mapAndSortContent(snapshot.data().content),
	} as DecadeFullFirestoreDocument;
}
