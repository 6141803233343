import {Stack, Typography} from "@mui/material";
import {getLocalizedString, LocalizedStringDto} from "@nitra/nkmb-card-components";
import {useTranslation} from "react-i18next";
import {Link, useLocation} from "react-router-dom";

export function DesktopNextArticle(props: {
	data: {
		title: LocalizedStringDto,
		image600x360url: string,
		firestoreId: string
	}
}) {
	const {title, image600x360url, firestoreId} = props.data;

	const location = useLocation()
	const {i18n} = useTranslation();
	const {t} = useTranslation();

	const currentLang = i18n.language;


	return (
		<Link
			to={(location.pathname.indexOf("footballSchool") !== -1) ? `/footballSchool/news/${firestoreId}` : `../${firestoreId}`}
			style={{
				textDecoration: 'none',
				color: 'black'
			}}
		>
			<Stack direction={"row"} spacing={4} justifyContent={"center"}>
				<div
					style={{
						width: 288,
						height: 140,
						borderRadius: "8px",
						backgroundImage: `url(${image600x360url})`,
						backgroundSize: 'cover',
						backgroundPosition: 'center',
						backgroundRepeat: 'no-repeat'
					}}
				/>
				<Stack direction={"column"} spacing={2}>
					<Typography variant={"gumbL"}>{t('article.nextArticle')}        </Typography>
					<Typography variant={"h4"}>{getLocalizedString(title, currentLang)}</Typography>
				</Stack>
			</Stack>
		</Link>
	)
}