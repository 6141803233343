import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import {initReactI18next} from 'react-i18next';
import slTranslations from './locales/sl.json';

i18n
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		resources: {
			sl: {
				translation: slTranslations
			},
			// en: {
			// 	translation: enTranslations
			// }
		},
		fallbackLng: 'sl',
		detection: {
			order: ['localStorage', 'navigator'],
			caches: ['localStorage']
		},
		interpolation: {
			escapeValue: false
		}
	});

export default i18n; 