import {Stack, SxProps, Typography, useTheme} from "@mui/material";
import {ShareArea, useIsMobileContext} from "@nitra/nkmb-card-components";
import {ArticleMatchMetaDataFirestoreDocument} from "../../../../models";
import {ArticleMatchCommonData} from "./ArticleMatchCommonData";
import {ArticleMatchStripBothTeamLineup} from "./ArticleMatchStripBothTeamLineup";

export function ArticleMatchStrip(props: {
	title: string,
	image1440x680url: string,
	image720x540url?: string,
	matchMetaData?: ArticleMatchMetaDataFirestoreDocument,
	sx?: SxProps,
}) {
	const {title, image1440x680url, image720x540url, matchMetaData} = props;
	const isMobile = useIsMobileContext()
	const theme = useTheme()

	if (!matchMetaData) return null

	return (
		<Stack sx={{
			maxWidth: {
				xs: "100%",
				sm: "348px",
			},
			width: {
				xs: "100%",
				sm: "348px",
			},
			minWidth: {
				xs: "100%",
				sm: "348px",
			},
			backgroundColor: theme.palette.text.primary,
			pb: 2,
			...props.sx,
		}}>
			{isMobile &&
                <Stack
                    justifyContent={"flex-end"}
                    alignItems={"flex-start"}
                    sx={{
						position: "relative",
						height: !!image720x540url ? "unset" : "150px",
						backgroundImage: `url(${image720x540url ?? image1440x680url})`,
						px: 2,
						py: 1,
						aspectRatio: !!image720x540url ? "720/540" : "unset",
						backgroundSize: "cover",
						backgroundPosition: "center",
					}}>
                    <div style={{
						background: "linear-gradient(to bottom,rgba(0, 0, 0, 0) 0%,rgba(18, 18, 18, 1.0)) 100%",
						zIndex: 0,
						position: "absolute", top: 0, left: 0, right: 0, bottom: 0
					}}/>
                    <Typography sx={{color: theme.palette.background.default, pb: 1, zIndex: 1,}}
                                variant={"h4"}>{title}</Typography>
                    <ShareArea brightText={true} sx={{ml: "unset", zIndex: 1}}/>
                </Stack>
			}
			<ArticleMatchCommonData matchMetaData={matchMetaData}/>
			<Typography sx={{
				color: theme.palette.secondary.main,
				pt: 10,
				pl: isMobile ? 6 : 1,
				width: "124px",
				textAlign: "center"
			}} variant={"bodyL"}>POSTAVE</Typography>
			<ArticleMatchStripBothTeamLineup matchMetaData={matchMetaData} isFirstEleven={true}/>
			<Typography sx={{
				color: theme.palette.background.paper,
				pt: 10,
				pl: isMobile ? 6 : 1,
				width: "124px",
				textAlign: "center"
			}} variant={"bodyL"}>REZERVE</Typography>
			<ArticleMatchStripBothTeamLineup matchMetaData={matchMetaData} isFirstEleven={false}/>
		</Stack>
	)
}

