import {Box, Grid, Stack, Typography} from "@mui/material";
import {useIsMobileContext} from "@nitra/nkmb-card-components";
import {Group} from "models/Group";
import {Person} from "models/Person";
import React from "react";
import {VotingPersonElement} from "./VotingPersonElement";

export function VotingGroupElement(props: {
	group: Group<Person>;
	onPersonSelect: (person: Person) => void;
}) {
	const {group, onPersonSelect} = props;
	const isMobile = useIsMobileContext();

	return (
		<Stack direction={"column"} sx={{width: "100%", pt: 7}}>
			<Typography variant={isMobile ? "podnaslovXS" : "podnaslovM"}>Glasuj s klikom na izbranega
				igralca:</Typography>
			<Grid container spacing={2} justifyContent={"space-between"} sx={{pt: 2}}>
				{group.items.map((person, index) => (
					<Grid key={index} item
					      xs={6}
					      sm={6}
					      md={4}
					      lg={3}
					      xl={3}
					      sx={{marginRight: index === group.items.length - 1 ? "auto" : "unset"}}
					>
						<Box style={{
							maxWidth: "331px",
							width: "100%",
							paddingTop: `min(100%, 331px)`,
							position: 'relative',
						}}>
							<Box
								style={{
									position: 'absolute',
									top: 0,
									left: 0,
									bottom: 0,
									right: 0,
									display: 'flex',
									justifyContent: 'center',
								}}>
								<VotingPersonElement
									person={person}
									onSelect={onPersonSelect}
								/>
							</Box>
						</Box>
					</Grid>
				))}
			</Grid>
		</Stack>
	);
} 